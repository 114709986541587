import gql from "graphql-tag";

export const allPromotionsList = gql`
  query allPromotionsList(
    $limit: Int
    $offset: Int
    $sort: PromotionsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: PromotionsWhereInput
  ) {
    allPromotionsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        title
        image
        body
        begin_date
        end_date
        active
        priority
      }
      count
    }
  }
`;

export const getPromotionsById = gql`
  query getPromotionsById($id: UUID!) {
    getPromotionsById(id: $id) {
      id
      created_at
      updated_at
      title
      image
      body
      begin_date
      end_date
      active
      priority
    }
  }
`;

export const createPromotions = gql`
  mutation createPromotions($input: [PromotionsCreateInput!]!) {
    createPromotions(input: $input) {
      id
      created_at
      updated_at
      title
      image
      body
      begin_date
      end_date
      active
      priority
    }
  }
`;
