import { allUsersList } from "../../Graphql/Users";
import { allPromotionsList } from "../../Graphql/Promotion";
import { allDepositLogsList } from "../../Graphql/Deposits";
import { allTransactionsList } from "../../Graphql/Transaction";
import { allReportsList } from "../../Graphql/Reports";
import { allProductsList } from "../../Graphql/Products";
import { allProductLayoutsList } from "../../Graphql/ProductLayouts";
import client from "../../Utils/client";
import errorHandler from "../../Utils/errorHandler";
import moment from "moment";

const getList = async (resource: any, params: any) => {
  console.log("GETLIST");
  let data;
  try {
    if (resource === "users") {
      let query;
      if (params.filter.email) {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              _and: {
                email: { _ilike: `%${params.filter.email}%` },
                _or: [
                  { roles: { _has_key: "Staff" } },
                  { roles: { _has_key: "Administrator" } },
                ],
              },
            },
          },
        };
      } else if (params.filter.roles) {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: { roles: { _has_key: params.filter.roles } },
          },
        };
      } else {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              _or: [
                { roles: { _has_key: "Staff" } },
                { roles: { _has_key: "Administrator" } },
              ],
            },
          },
        };
      }
      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allUsersList.data,
        total: gql.allUsersList.count,
      };
    } else if (resource === "promotions") {
      let query;
      if (params.filter.status) {
        query = {
          query: allPromotionsList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: { active: { _eq: params.filter.status === "active" } },
          },
        };
      } else {
        query = {
          query: allPromotionsList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
          },
        };
      }
      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allPromotionsList.data,
        total: gql.allPromotionsList.count,
      };
    } else if (resource === "deposits") {
      let where = {};

      if (params.filter.vendor_id) {
        where = { ...where, created_by: { _eq: params.filter.vendor_id } };
      }

      if (params.filter.status) {
        where = { ...where, status: { _eq: params.filter.status } };
      }

      if (params.filter.channel) {
        where = { ...where, channel: { _eq: params.filter.channel } };
      }
      if (params.filter.from && params.filter.to) {
        where = {
          ...where,
          _and: [
            { created_at: { _gte: moment(params.filter.from).format() } },
            { created_at: { _lte: moment(params.filter.to).format() } },
          ],
        };
      }

      const query: any = {
        query: allDepositLogsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
          where,
        },
      };

      const gql = await client.gql.query(query);
      console.log(gql);
      if (params.filter.type === "reference") {
        const dataFilter = gql.allDepositLogsList.data;
        const uniquex = [
          ...new Set(dataFilter.map((item: { channel: any }) => item.channel)),
        ];
        const transform = uniquex.map((x) => ({ id: x, channel: x }));
        data = {
          data: transform,
          total: transform.length,
        };
        console.log(data);
      } else {
        data = {
          data: gql.allDepositLogsList.data,
          total: gql.allDepositLogsList.count,
        };
      }
    } else if (resource === "reports") {
      const query = {
        query: allReportsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
        },
      };

      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allReportsList.data,
        total: gql.allReportsList.count,
      };
    } else if (resource === "layouts") {
      const query = {
        query: allProductLayoutsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
        },
      };

      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allProductLayoutsList.data,
        total: gql.allProductLayoutsList.count,
      };
    } else if (resource === "transactions") {
      let where = {};

      if (params.filter.vendor_id) {
        where = { ...where, vendor_id: { _eq: params.filter.vendor_id } };
      }
      if (params.filter.target) {
        where = { ...where, target: { _ilike: `%${params.filter.target}%` } };
      }
      if (params.filter.order_id) {
        where = {
          ...where,
          order_id: { _ilike: `%${params.filter.order_id}%` },
        };
      }
      if (params.filter.status) {
        where = { ...where, status: { _eq: params.filter.status } };
      }
      if (params.filter.from && params.filter.to) {
        where = {
          ...where,
          _and: [
            { created_at: { _gte: moment(params.filter.from).format() } },
            { created_at: { _lte: moment(params.filter.to).format() } },
          ],
        };
      }
      const query = {
        query: allTransactionsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
          where,
        },
      };

      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allTransactionsList.data,
        total: gql.allTransactionsList.count,
      };
    } else if (resource === "products") {
      let where = {};

      if (params.filter.name) {
        where = { ...where, name: { _ilike: `%${params.filter.name}%` } };
      }
      if (params.filter.category) {
        where = { ...where, category: { _eq: params.filter.category } };
      }

      const query = {
        query: allProductsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
          where,
        },
      };

      const gql = await client.gql.query(query);
      console.log(gql);
      if (params.filter.forFilter === "name") {
        const dataFilter = gql.allProductsList.data;
        const uniquex = [
          ...new Set(dataFilter.map((item: { name: any }) => item.name)),
        ];
        const transform = uniquex.map((x) => ({ id: x, name: x }));
        data = {
          data: transform,
          total: transform.length,
        };
        console.log(data);
      } else if (params.filter.forFilter === "category") {
        const dataFilter = gql.allProductsList.data;
        const uniquex = [
          ...new Set(
            dataFilter.map((item: { category: any }) => item.category)
          ),
        ];
        const transform = uniquex.map((x) => ({ id: x, category: x }));
        data = {
          data: transform,
          total: transform.length,
        };
        console.log(data);
      } else {
        data = {
          data: gql.allProductsList.data,
          total: gql.allProductsList.count,
        };
      }
    } else if (resource === "vendors") {
      let where: any = {
        roles: { _has_key: params.filter.roles },
        verified: { _eq: params.filter.active },
      };

      if (params.filter.status) {
        where = {
          ...where,
          vendor_disabled_at: { _is_null: params.filter.status === "ACTIVE" },
        };
      }
      if (params.filter.type) {
        where = { ...where, vendor_type: { _eq: params.filter.type } };
      }

      const query = {
        query: allUsersList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
          where: where,
        },
      };
      const gql = await client.gql.query(query);
      console.log(gql);
      data = {
        data: gql.allUsersList.data,
        total: gql.allUsersList.count,
      };
    } else {
      const response = await fetch(
        "https://jsonplaceholder.typicode.com/users"
      );
      const fetchData = await response.json();
      console.log(fetchData);
      data = {
        data: fetchData,
        total: fetchData.length,
      };
    }

    return data;
  } catch (error) {
    await errorHandler(error);
  }
};

export default getList;
