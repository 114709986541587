const deleteMany = async (resource: any, params: any) => {
    console.log("deleteMany");
    const data = {
      data: [],
      total: 0,
    };
    return data;
  };
  
  export default deleteMany;
  