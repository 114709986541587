import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  Grid,
  IconButton,
  Tabs,
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import TabMat from "@material-ui/core/Tab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import client from "../Utils/client";
import isProd from "../Utils/variabels";

interface review {
  openImageReview: any;
  vat_img: string;
  arc_img: string;
  vendor_type: string;
  setImageReview: any;
}

function a11yProps(index: Number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ImageReviewer = (props: review) => {
  const [value2, setValue2] = React.useState(0);
  const [img, setImg] = React.useState("");
  const [img2, setImg2] = React.useState("");

  const handleChange2 = (event: any, newValue: any) => {
    setValue2(newValue);
  };

  const lookFile2 = async (filename: string) => {
    const download = await client.storage.downloadUrl({
      params: {
        bucket: isProd.privateBucket,
      },
      fileId: filename,
    });
    console.log(download);
    // window.open(download.data.url, "_blank");
    return download.data.url;
    // setImg(
    //   "https://www.ups.com/assets/resources/images/knowledge-center/934x495/m10-934x495-hero-main-A-guide-to-value-added-tax.jpg"
    // );
  };

  const setUpImage = async () => {
    const arc = await lookFile2(props.arc_img);
    setImg(arc);

    if (props.vendor_type === "STORE") {
      const vat = await lookFile2(props.vat_img);
      setImg2(vat);
    }
  };

  React.useEffect(() => {
    setUpImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={props.openImageReview}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Image Viewer</DialogTitle>
      <DialogContent>
        <Grid item xs style={{ marginLeft: "10px" }}>
          <Grid container direction="row">
            <AppBar position="static" style={{ marginBottom: "20px" }}>
              <Tabs
                value={value2}
                onChange={handleChange2}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                <TabMat label="Arc image" {...a11yProps(0)} />
                {props.vendor_type === "STORE" && (
                  <TabMat label="VAT image" {...a11yProps(1)} />
                )}
              </Tabs>
            </AppBar>
            {img !== "" && value2 === 0 ? (
              <TransformWrapper defaultScale={1} wheel={{ step: 20 }}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ alignSelf: "flex-end" }}>
                      <IconButton onClick={zoomIn}>
                        <ZoomInIcon />
                      </IconButton>
                      <IconButton onClick={zoomOut}>
                        <ZoomOutIcon />
                      </IconButton>
                      <IconButton onClick={resetTransform}>
                        <FullscreenIcon />
                      </IconButton>
                    </div>
                    <div style={{ alignSelf: "center", width: "100%" }}>
                      <TransformComponent>
                        <img
                          src={img}
                          alt="test"
                          style={{ maxWidth: "100%" }}
                        />
                      </TransformComponent>
                    </div>
                  </div>
                )}
              </TransformWrapper>
            ) : (
              ""
            )}
            {img2 !== "" && value2 === 1 ? (
              <TransformWrapper defaultScale={1} wheel={{ step: 50 }}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                        <IconButton onClick={zoomIn}>
                          <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={zoomOut}>
                          <ZoomOutIcon />
                        </IconButton>
                        <IconButton onClick={resetTransform}>
                          <FullscreenIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", width: "100%" }}>
                      <TransformComponent>
                        <img src={img2} alt="test" style={{ width: "100%" }} />
                      </TransformComponent>
                    </div>
                  </div>
                )}
              </TransformWrapper>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => props.setImageReview(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageReviewer;
