import errorHandler from "../../Utils/errorHandler";

const getMany = async (resource: any, params: any) => {
  try {
    console.log("getMany");
    const data = {
      data: [],
      total: 0,
    };
    return data;
  } catch (error) {
    await errorHandler(error);
  }
};

export default getMany;
