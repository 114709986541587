import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
  TextInput,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  Show,
  ShowController,
  SimpleShowLayout,
} from "react-admin";

const ProductStyle = (record: any, index: number) => ({
  backgroundColor: record.available ? "" : "#ffcdd2",
});

export const ProductList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<PostFilter />}
    exporter={false}
  >
    <Datagrid expand={<ExpandShow />} rowStyle={ProductStyle}>
      <TextField source="name" />
      <FunctionField
        label="Description"
        render={(record: any) =>
          record.description === null
            ? "-"
            : record.description.length > 80
            ? `${record.description.substring(0, 80)}...`
            : record.description
        }
      />
      <FunctionField
        label="Category"
        render={(record: any) =>
          record.category === null ? "-" : record.category
        }
      />
      <TextField source="retail_price" />
      <BooleanField source="available" />
      <DateField locales="en-TT" source="created_at" showTime />
      <DateField locales="en-TT" source="updated_at" showTime />
    </Datagrid>
  </List>
);

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <ReferenceInput
      label="Category"
      source="category"
      perPage={100000}
      reference="products"
      filter={{ forFilter: "category" }}
      alwaysOn
    >
      <AutocompleteInput optionText="category" />
    </ReferenceInput>
  </Filter>
);
const ExpandShow = (props: any) => (
  <div>
    <ShowController {...props}>
      {(controllerProps: any) => (
        <Show {...props} title=" ">
          <SimpleShowLayout>
            <TextField source="name" />
            <FunctionField
              label="Description"
              render={(record: any) =>
                record.description === null ? "-" : record.description
              }
            />
            <FunctionField
              label="Category"
              render={(record: any) =>
                record.category === null ? "-" : record.category
              }
            />
            <TextField source="retail_price" />
            <FunctionField
              label="Cost Agent"
              render={(record: any) =>
                record.cost_agent === null ? "-" : record.cost_agent
              }
            />
            <FunctionField
              label="Cost Store"
              render={(record: any) =>
                record.cost_store === null ? "-" : record.cost_store
              }
            />
            <BooleanField source="available" />
            <DateField locales="en-TT" source="created_at" showTime />
            <DateField locales="en-TT" source="updated_at" showTime />
          </SimpleShowLayout>
        </Show>
      )}
    </ShowController>
  </div>
);
