import gql from "graphql-tag";

export const allProductsList = gql`
  query allProductsList(
    $limit: Int
    $offset: Int
    $sort: ProductsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: ProductsWhereInput
  ) {
    allProductsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        name
        description
        category
        retail_price
        cost_agent
        cost_store
        pricing_schema
        available
        fascon_sync_timestamp
        code
      }
      count
    }
  }
`;

export const getProductsById = gql`
  query getProductsById($id: UUID!) {
    getProductsById(id: $id) {
      id
      created_at
      updated_at
      name
      description
      category
      retail_price
      cost_agent
      cost_store
      pricing_schema
      available
      fascon_sync_timestamp
      code
    }
  }
`;
