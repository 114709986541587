import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  BooleanField,
  useRefresh,
  FunctionField,
  EditButton,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  useNotify,
  Edit,
  Filter,
  Toolbar,
  SaveButton,
} from "react-admin";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabMat from "@material-ui/core/Tab";
import VendorDepo from "../Components/VendorDepositLogs";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import ReactJson from "react-json-view";
import IframeResizer from "iframe-resizer-react";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { CancelOutlined, CheckCircleOutline } from "@material-ui/icons";
import TextFieldMui from "@material-ui/core/TextField";
import client from "../Utils/client";
import ImageIcon from "@material-ui/icons/Image";
import isProd from "../Utils/variabels";
import errorhandler from "../Utils/errorHandler";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import ImageReviewer from "../Components/ImageReview";
import ImageReviewZoom from "../Components/ImageReviewZoom";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
function a11yProps(index: Number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const VendorEdit = (props: any) => {
  const notify = useNotify();

  const UserEditToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} redirect="/vendors" />
    </Toolbar>
  );
  const onFailure = (error: any) => {
    console.log(error.response);
    if (error.response.data.errors[0].code === "PERMISSION_DENIED") {
      notify(`Only Administrator can edit users`);
    } else {
      notify(`Could not edit users`);
    }
  };
  return (
    <Edit title="Edit User" onFailure={onFailure} undoable={false} {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextField source="name" />
        <TextField label="Email" source="email" />
        <FunctionField
          label="MSISDN"
          render={(record: any) =>
            record.msisdn === null ? "-" : record.msisdn
          }
        />
        <FunctionField
          label="LINE ID"
          render={(record: any) =>
            record.line_id === null ? "-" : record.line_id
          }
        />

        <TextField source="vendor_type" label="Type" />
        <BooleanInput source="verified" label="Verified user" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <BooleanInput
                source="active"
                label={formData.active ? "Active vendor" : "Inactive Vendor"}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export const VendorList = (props: any) => {
  const [value, setValue] = React.useState(0);
  const [value2, setValue2] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [dateExp, setDateExp] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<any>({});
  const [openImageReview, setImageReview] = React.useState<boolean>(false);
  const [img, setImg] = React.useState("");
  const [img2, setImg2] = React.useState("");

  const table = client.getTable("users");
  const table2 = client.getTable("vendor_details");
  const refresh = useRefresh();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const handleChange2 = (event: any, newValue: any) => {
    setValue2(newValue);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setName("");
    setNumber("");
    setDateExp("");
    setNote("");
  };

  const selectVendor = async (record: any) => {
    setSelected(record);
    const arc = await lookFile2(
      record.vendor_details_user_id_list.data[0].arc_image
    );
    setImg(arc);

    if (record.vendor_type === "STORE") {
      const vat = await lookFile2(
        record.vendor_details_user_id_list.data[0].vat_image
      );
      setImg2(vat);
    }
    setOpenDialog(true);
  };

  const ButtonSetting = ({ record }: any) => {
    return (
      <IconButton
        color="primary"
        aria-label="add to shopping cart"
        onClick={() => selectVendor(record)}
      >
        <SettingsIcon />
      </IconButton>
    );
  };

  const updateStatus = async (status: any) => {
    setLoading(true);
    let editData = selected;
    console.log(selected);

    let vendorData = selected.vendor_details_user_id_list.data[0];
    if (status === "APPROVE") {
      editData = {
        ...editData,
        verified: true,
        registration_metadata: { status: "APPROVE", note },
      };
    } else {
      editData = {
        ...editData,
        verified: false,
        registration_metadata: { status: "REJECT", note },
      };
    }
    console.log(editData);

    const update = await table.update({
      id: selected.id,
      data: editData,
    });

    console.log(update);
    if (status === "APPROVE") {
      const update2 = await table2.update({
        id: vendorData.id,
        data: {
          ...vendorData,
          vat_name: name === "" ? null : name,
          vat_number: number === "" ? null : number,
          arc_expiry_date: dateExp === "" ? null : moment(dateExp).format(),
        },
      });

      console.log(update2);
    }
    setLoading(false);
    setNote("");
    setOpenDialog(false);
    refresh();
  };

  const lookFile2 = async (filename: string) => {
    const download = await client.storage.downloadUrl({
      params: {
        bucket: isProd.privateBucket,
      },
      fileId: filename,
    });
    console.log(download);
    // window.open(download.data.url, "_blank");
    return download.data.url;
    // setImg(
    //   "https://www.ups.com/assets/resources/images/knowledge-center/934x495/m10-934x495-hero-main-A-guide-to-value-added-tax.jpg"
    // );
  };

  const PostFilter = (props: any) => (
    <Filter {...props}>
      <SelectInput
        source="status"
        alwaysOn
        choices={[
          { id: "ACTIVE", name: "Active" },
          { id: "INACTIVE", name: "Inactive" },
        ]}
      />
      <SelectInput
        source="type"
        alwaysOn
        choices={[
          { id: "AGENT", name: "Agent" },
          { id: "STORE", name: "Store" },
        ]}
      />
    </Filter>
  );

  return (
    <div>
      <List
        {...props}
        bulkActionButtons={false}
        filter={{
          roles: "Vendor",
          active: value === 0,
        }}
        exporter={false}
        filters={<PostFilter />}
        empty={false}
      >
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <TabMat
                label="Active Vendors"
                icon={<VerifiedUserIcon />}
                {...a11yProps(0)}
              />
              <TabMat
                label="Pending Vendors"
                icon={<PersonAddIcon />}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          {value === 0 && (
            <Datagrid rowClick="show">
              <TextField source="name" />
              <FunctionField
                label="MSISDN"
                render={(record: any) =>
                  record.msisdn === null ? "-" : record.msisdn
                }
              />
              <FunctionField
                label="LINE ID"
                render={(record: any) =>
                  record.line_id === null
                    ? "-"
                    : typeof record.line_id === "string" &&
                      record.line_id.length > 20
                    ? `${record.line_id.substring(0, 15)}...`
                    : record.line_id
                }
              />
              <FunctionField
                label="Balance"
                render={
                  (record: any) =>
                    record.ledgers_user_id_list &&
                    (record.ledgers_user_id_list.data.length === 0
                      ? "0"
                      : record.ledgers_user_id_list.data[0].balance)
                  // record.ledgers_user_id_list.data[0].balance
                }
              />
              <BooleanField source="verified" />
              <FunctionField
                label="Active"
                render={(record: any) =>
                  record.vendor_disabled_at === null ? (
                    <DoneIcon />
                  ) : (
                    <ClearIcon />
                  )
                }
              />
              <TextField source="vendor_type" label="Type" />

              {/* <DateField
                locales="en-TT"
                source="vendor_details_user_id_list.data[0].arc_expiry_date"
                label="Arc exp date"
                showTime
              /> */}
              <FunctionField
                label="Arc Expire"
                render={(record: any) =>
                  record.vendor_details_user_id_list &&
                  (record.vendor_details_user_id_list.data[0]
                    .arc_expiry_date === null
                    ? "-"
                    : moment(
                        record.vendor_details_user_id_list.data[0]
                          .arc_expiry_date
                      ).format("DD/MM/YYYY, h:mm:ss a"))
                }
              />

              <DateField locales="en-TT" source="created_at" showTime />
              <DateField locales="en-TT" source="updated_at" showTime />
              <EditButton />
            </Datagrid>
          )}
          {value === 1 && (
            <Datagrid>
              <TextField source="name" />
              <FunctionField
                label="MSISDN"
                render={(record: any) =>
                  record.msisdn === null ? "-" : record.msisdn
                }
              />

              <FunctionField
                label="LINE ID"
                render={(record: any) =>
                  record.line_id === null
                    ? "-"
                    : typeof record.line_id === "string" &&
                      record.line_id.length > 20
                    ? `${record.line_id.substring(0, 15)}...`
                    : record.line_id
                }
              />
              <BooleanField source="verified" />
              <TextField source="vendor_type" label="Type" />

              <DateField locales="en-TT" source="created_at" showTime />
              <DateField locales="en-TT" source="updated_at" showTime />
              <ButtonSetting />
            </Datagrid>
          )}
        </div>
      </List>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth={true}
        // onClose={handleCloseDialog}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" justify="space-between">
            <Grid>
              <span>
                <PersonAddIcon
                  style={{ fontSize: "25px", marginBottom: "-6px" }}
                />
              </span>
              <span> Approve Vendor</span>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                size="small"
              >
                <CancelOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> Desc {selected.id || "NO"}</DialogContentText> */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs={5} spacing={1}>
              {selected.vendor_details_user_id_list && (
                <React.Fragment>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Name
                    </div>
                    <div>{selected.name || "-"}</div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      LINE ID
                    </div>
                    <div>{selected.line_id || "-"}</div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Address
                    </div>
                    <div>
                      {selected.vendor_details_user_id_list.data[0].address ||
                        "-"}
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      City
                    </div>
                    <div>
                      {selected.vendor_details_user_id_list.data[0].city || "-"}
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Postal Code
                    </div>
                    <div>
                      {selected.vendor_details_user_id_list.data[0]
                        .postal_code || "-"}
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Type
                    </div>
                    <div>{selected.vendor_type || "-"}</div>
                  </div>
                  {/* <div style={{ marginBottom: "10px" }}>
                    {selected.vendor_details_user_id_list.data[0].arc_image !==
                      null && (
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        startIcon={<ImageIcon />}
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                          lookFile(
                            selected.vendor_details_user_id_list.data[0]
                              .arc_image
                          )
                        }
                      >
                        Arc image
                      </Button>
                    )}

                    {selected.vendor_type === "STORE" &&
                      selected.vendor_details_user_id_list.data[0].vat_image !==
                        null && (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          startIcon={<ImageIcon />}
                          onClick={() =>
                            lookFile(
                              selected.vendor_details_user_id_list.data[0]
                                .vat_image
                            )
                          }
                        >
                          vat image
                        </Button>
                      )}
                  </div> */}
                  <Divider />
                  <TextFieldMui
                    label="Note"
                    size="small"
                    variant="outlined"
                    required
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    fullWidth
                    style={{ marginTop: "20px" }}
                  />
                  {selected.vendor_type === "STORE" && (
                    <TextFieldMui
                      label="Company Name"
                      size="small"
                      variant="outlined"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      style={{ marginTop: "10px" }}
                    />
                  )}
                  {selected.vendor_type === "STORE" && (
                    <TextFieldMui
                      label="VAT Number"
                      size="small"
                      variant="outlined"
                      value={number}
                      required
                      onChange={(e) => setNumber(e.target.value)}
                      fullWidth
                      style={{ marginTop: "10px" }}
                    />
                  )}
                  <TextFieldMui
                    id="datetime-local"
                    size="small"
                    value={dateExp}
                    onChange={(e) => setDateExp(e.target.value)}
                    label="Arc Expired Date"
                    variant="outlined"
                    type="datetime-local"
                    style={{ marginTop: "10px" }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs style={{ marginLeft: "10px" }}>
              <Grid container direction="row" justify="center">
                <AppBar position="static" style={{ marginBottom: "20px" }}>
                  <Tabs
                    value={value2}
                    onChange={handleChange2}
                    aria-label="simple tabs example"
                    variant="fullWidth"
                  >
                    <TabMat label="Arc image" {...a11yProps(0)} />
                    {selected.vendor_type === "STORE" && (
                      <TabMat label="VAT image" {...a11yProps(1)} />
                    )}
                  </Tabs>
                </AppBar>
                {img !== "" && value2 === 0 && (
                  <TransformWrapper defaultScale={1} wheel={{ step: 20 }}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "25px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <IconButton onClick={zoomIn}>
                              <ZoomInIcon />
                            </IconButton>
                            <IconButton onClick={zoomOut}>
                              <ZoomOutIcon />
                            </IconButton>
                            <IconButton onClick={resetTransform}>
                              <FullscreenIcon />
                            </IconButton>
                          </div>
                        </div>
                        <TransformComponent>
                          <img src={img} alt="test" style={{ width: "100%" }} />
                        </TransformComponent>
                      </div>
                    )}
                  </TransformWrapper>
                )}
                {img2 !== "" && value2 === 1 && (
                  <TransformWrapper defaultScale={1} wheel={{ step: 20 }}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <IconButton onClick={zoomIn}>
                              <ZoomInIcon />
                            </IconButton>
                            <IconButton onClick={zoomOut}>
                              <ZoomOutIcon />
                            </IconButton>
                            <IconButton onClick={resetTransform}>
                              <FullscreenIcon />
                            </IconButton>
                          </div>
                        </div>
                        <TransformComponent>
                          <img
                            src={img2}
                            alt="test"
                            style={{ width: "100%" }}
                          />
                        </TransformComponent>
                      </div>
                    )}
                  </TransformWrapper>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "10px", marginRight: "10px" }}>
          <Button
            onClick={() => updateStatus("REJECT")}
            color="secondary"
            startIcon={<CancelOutlined />}
            variant="contained"
            disabled={note === "" || loading}
          >
            {loading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "REJECT"
            )}
          </Button>
          <Button
            onClick={() => updateStatus("APPROVE")}
            color="primary"
            variant="contained"
            startIcon={<CheckCircleOutline />}
            disabled={note === "" || loading}
          >
            {loading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "APPROVE"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <ImageReviewer
        openImageReview={openImageReview}
        img={img}
        setImageReview={setImageReview}
      />
    </div>
  );
};

export const VendorShow = (props: any) => {
  const [openImageReview, setImageReview] = React.useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const lookFile = async () => {
    setImageReview(true);
  };

  const ImageButton = ({ record }: any) => {
    return (
      <div style={{ marginTop: "20px" }}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<ImageIcon />}
          style={{ marginRight: "10px" }}
          onClick={() => lookFile()}
        >
          VIEW ARC & VAT Image
        </Button>
      </div>
    );
  };

  const ReviewImage = ({ record }: any) => {
    return (
      <ImageReviewZoom
        openImageReview={openImageReview}
        setImageReview={setImageReview}
        vat_img={record.vendor_details_user_id_list.data[0].vat_image}
        arc_img={record.vendor_details_user_id_list.data[0].arc_image}
        vendor_type={record.vendor_type}
      />
    );
  };

  const JsonReviewer = ({ record }: any) => {
    return (
      <ReactJson
        src={record.line_metadata}
        collapsed={0}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        collapseStringsAfterLength={50}
      />
    );
  };
  JsonReviewer.defaultProps = { label: "LINE metadata", addLabel: true };

  React.useEffect(() => {
    metabaseFetch();
  }, []);
  const metabaseFetch = async () => {
    setLoading(true);
    try {
      const idVendor = window.location.pathname.split("/")[2];
      const fetch = await client.invokeApi("vendor-performance", {
        vendorId: idVendor,
      });
      console.log(fetch);
      setIframeUrl(fetch.data.iframeUrl);
      setLoading(false);
    } catch (e) {
      console.log(e);
      errorhandler(e);
    }
  };

  return (
    <React.Fragment>
      <Show {...props} title=" ">
        <SimpleShowLayout>
          <TabbedShowLayout>
            <Tab label="profile" path="">
              <TextField source="name" />
              <FunctionField
                label="LINE Display Name"
                render={(record: any) =>
                  record.line_metadata ? record.line_metadata.displayName : "-"
                }
              />
              <FunctionField
                label="LINE ID"
                render={(record: any) =>
                  record.line_id === null ? "-" : record.line_id
                }
              />
              <FunctionField
                label="MSISDN"
                render={(record: any) =>
                  record.msisdn === null ? "-" : record.msisdn
                }
              />
              <FunctionField
                label="Balance"
                render={(record: any) =>
                  record.ledgers_user_id_list.data.length === 0
                    ? "0"
                    : record.ledgers_user_id_list.data[0].balance
                }
              />
              <JsonReviewer />

              <BooleanField source="verified" />
              <TextField source="vendor_type" label="Type" />

              <FunctionField
                label="Arc Expire date"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].arc_expiry_date ===
                  null
                    ? "-"
                    : moment(
                        record.vendor_details_user_id_list.data[0]
                          .arc_expiry_date
                      ).format("DD/MM/YYYY, h:mm:ss a")
                }
              />

              <DateField locales="en-TT" source="created_at" showTime />
              <DateField locales="en-TT" source="updated_at" showTime />
            </Tab>
            <Tab label="Vendor Detail" path="vendor">
              <FunctionField
                label="Company Name"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].vat_name || "-"
                }
              />
              <FunctionField
                label="Company Number"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].vat_number || "-"
                }
              />
              <FunctionField
                label="Address"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].address || ""
                }
              />
              <FunctionField
                label="City"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].city || ""
                }
              />
              <FunctionField
                label="Postal Code"
                render={(record: any) =>
                  record.vendor_details_user_id_list.data[0].postal_code || ""
                }
              />

              <ImageButton />
              <ReviewImage />
            </Tab>
            <Tab label="deposit logs" path="depositlogs">
              <VendorDepo />
            </Tab>
            <Tab label="Performance" path="performance">
              <div>
                {/* <iframe
                  title="vendor-peformance"
                  src={iframeUrl}
                  frameBorder={0}
                  height={500}
                  style={{ width: "100%" }}
                  allowTransparency
                /> */}
                {loading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                      style={{
                        width: "50px",
                        height: "50px",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    />
                  </div>
                )}
                <IframeResizer
                  heightCalculationMethod="bodyScroll"
                  frameBorder={0}
                  width="100%"
                  src={iframeUrl}
                />
              </div>
            </Tab>
          </TabbedShowLayout>
        </SimpleShowLayout>
      </Show>
    </React.Fragment>
  );
};
