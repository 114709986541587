import React from "react";

import MaterialTable from "material-table";

interface Props {
  data: any;
  setData: any;
}

const OptsForm = ({ data, setData }: Props) => {
  const { useState } = React;

  const [columns] = useState<any>([
    // {
    //   title: "Name",
    //   field: "name",
    //   editComponent: (props: any) => (
    //     <input
    //       type="text"
    //       value={props.value}
    //       onChange={(e) => props.onChange(e.target.value)}
    //     />
    //   ),
    // },
    { title: "Id", field: "id", sorting: false },
    { title: "Label", field: "label", sorting: false },
    { title: "Alias", field: "alias", sorting: false },

    // {
    //   title: "Birth Place",
    //   field: "birthCity",
    //   lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    // },
  ]);

  //   const [data, setData] = useState<Array<any>>([]);

  return (
    <MaterialTable
      title="Inputs"
      columns={columns}
      options={{
        search: false,
        actionsColumnIndex: -1,
      }}
      data={data}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setData([...data, newData]);

              resolve("ok");
            }, 1000);
          }),
        onRowUpdate: (newData, oldData: any) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              resolve("ok");
            }, 1000);
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              resolve("ok");
            }, 1000);
          }),
      }}
    />
  );
};

export default OptsForm;
