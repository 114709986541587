import React from "react";

import MaterialTable from "material-table";
import ChipInput from "material-ui-chip-input";
import Chip from "@material-ui/core/Chip";

interface Props {
  data: any;
  setData: any;
  schema: string;
}

const OptsForm = ({ data, setData, schema }: Props) => {
  const { useState } = React;
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  const [columns] = useState<any>([
    // {
    //   title: "Name",
    //   field: "name",
    //   editComponent: (props: any) => (
    //     <input
    //       type="text"
    //       value={props.value}
    //       onChange={(e) => props.onChange(e.target.value)}
    //     />
    //   ),
    // },
    // { title: "Regex", field: "regex", sorting: false },
    { title: "Label", field: "label", sorting: false },
    {
      title: "Categories",
      field: "categories",
      sorting: false,
      render: (rowData: any) => {
        return (
          <div>
            {rowData.categories?.map((item: string) => (
              <Chip
                color="primary"
                size="small"
                label={item}
                style={{ marginRight: "5px" }}
              />
            ))}
          </div>
        );
      },
      editComponent: (props: any) => (
        <ChipInput
          style={{ maxWidth: "300px" }}
          placeholder="enter to insert categories"
          value={props.value}
          InputProps={{ className: "optsgroups" }}
          onChange={(e) => {
            console.log(e);
            props.onChange(e);
          }}
          onKeyDown={(e) => handleKey(e)}
          onDelete={(chip, index) => {
            const data = props.value;
            data.splice(index, 1);
            props.onChange(data);
          }}
          onAdd={(e) => {
            props.onChange(props.value.concat(e));
          }}
        />
      ),
    },

    // {
    //   title: "Birth Place",
    //   field: "birthCity",
    //   lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    // },
  ]);

  const [columnsPattern] = useState<any>([
    // {
    //   title: "Name",
    //   field: "name",
    //   editComponent: (props: any) => (
    //     <input
    //       type="text"
    //       value={props.value}
    //       onChange={(e) => props.onChange(e.target.value)}
    //     />
    //   ),
    // },
    { title: "Regex", field: "regex", sorting: false },
    { title: "Label", field: "label", sorting: false },
    {
      title: "Categories",
      field: "categories",
      sorting: false,
      render: (rowData: any) => {
        return (
          <div>
            {rowData.categories?.map((item: string) => (
              <Chip
                color="primary"
                size="small"
                label={item}
                style={{ marginRight: "5px" }}
              />
            ))}
          </div>
        );
      },
      editComponent: (props: any) => (
        <ChipInput
          style={{ maxWidth: "300px" }}
          placeholder="enter to insert categories"
          value={props.value}
          InputProps={{ className: "optsgroups" }}
          onChange={(e) => {
            console.log(e);
            props.onChange(e);
          }}
          onKeyDown={(e) => handleKey(e)}
          onDelete={(chip, index) => {
            const data = props.value;
            data.splice(index, 1);
            props.onChange(data);
          }}
          onAdd={(e) => {
            props.onChange(props.value.concat(e));
          }}
        />
      ),
    },

    // {
    //   title: "Birth Place",
    //   field: "birthCity",
    //   lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    // },
  ]);

  //   const [data, setData] = useState<Array<any>>([]);

  return (
    <MaterialTable
      title="Options"
      columns={schema === "PATTERN_FIRST" ? columnsPattern : columns}
      options={{
        search: false,
        actionsColumnIndex: -1,
      }}
      data={data}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setData([...data, newData]);
              resolve("ok");
            }, 1000);
          }),
        onRowUpdate: (newData, oldData: any) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              resolve("ok");
            }, 1000);
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              resolve("ok");
            }, 1000);
          }),
      }}
    />
  );
};

export default OptsForm;
