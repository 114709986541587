/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import client from "../Utils/client";

import { AuthProvider } from "react-admin";

const auth: AuthProvider = {
  login: async (params: any) => {
    try {
      console.log(params);
      console.log(client);
      const login = await client.auth.login("local", params);
      console.log(login);
      const role = await client.user.get();
      console.log(role);
      window.location.replace("/");
      return Promise.resolve({ redirectTo: "/" });
    } catch (error) {
      console.log(error);

      return error;
    }
  },

  checkError: async (error: any) => Promise.resolve(),
  checkAuth: () => {
    return localStorage.getItem("MBaaS.auth.accessToken")
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    // const data = localStorage.getItem("MBaaS.auth.accessToken");
    const role = localStorage.getItem("permission");

    return role ? Promise.resolve(role) : Promise.reject();
  },
  logout: async () => {
    console.log("logout");
    const logout = await client.user.logout();
    localStorage.removeItem("permission");
    localStorage.removeItem("profile");
    console.log(logout);

    return Promise.resolve();
  },
} as AuthProvider;

export default auth;
