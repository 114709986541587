import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  Filter,
  required,
  TextInput,
  SimpleForm,
  Create,
  DateField,
  ImageInput,
  ImageField,
  FunctionField,
  Edit,
  EditButton,
  useNotify,
  DateTimeInput,
  Labeled,
  FormDataConsumer,
  BooleanInput,
  BooleanField,
  ReferenceInput,
} from "react-admin";
// @ts-ignore
import RichTextInput from "ra-input-rich-text";

const PromoRowStyle = (record: any, index: number) => ({
  backgroundColor: record.active ? "white" : "#e5e5e5",
});

export const PromotionsList = (props: any) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<PromoFilter />}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: "priority", order: "ASC" }}
    >
      <Datagrid rowStyle={PromoRowStyle}>
        <TextField source="title" />
        <FunctionField
          label="Description"
          render={(record: any) =>
            record.body === null
              ? "-"
              : record.body.length > 25
              ? `${record.body.substring(0, 80)}...`
              : record.body
          }
        />
        {/* <TextField source="priority" /> */}
        <BooleanField source="active" />
        <DateField locales="en-TT" source="begin_date" showTime />
        <DateField locales="en-TT" source="end_date" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const PromoFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      choices={[
        { id: "active", name: "ACTIVE" },
        { id: "disable", name: "DISABLE" },
      ]}
      alwaysOn
    />
  </Filter>
);

export const PromoCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="title" validate={[required()]} />
        <DateTimeInput source="begin_date" />
        <DateTimeInput source="end_date" />
        <SelectInput
          label="Priority Position"
          source="position"
          resettable
          choices={[
            { id: "before", name: "Before" },
            { id: "after", name: "After" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, record, dispatch, ...rest }: any) =>
            formData.position !== undefined && (
              <ReferenceInput
                label="Priority Anchor"
                source="priorityValue"
                reference="promotions"
                perPage={100000}
                validate={[required()]}
                style={{ width: "260px" }}
              >
                <SelectInput optionText="title" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <BooleanInput
                source="active"
                label={formData.active ? "Active" : "Non Active"}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <ImageInput
          source="image"
          label="Cover Image"
          accept="image/*"
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <RichTextInput source="body" />
      </SimpleForm>
    </Create>
  );
};

export const PromotionsEdit = (props: any) => {
  const notify = useNotify();

  const onFailure = (error: any) => {
    console.log(error.response);
    if (error.response.data.errors[0].code === "PERMISSION_DENIED") {
      notify(`Only Administrator can edit users`);
    } else {
      notify(`Could not edit users`);
    }
  };
  return (
    <Edit title="Edit User" onFailure={onFailure} undoable={false} {...props}>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <DateTimeInput source="begin_date" />
        <DateTimeInput source="end_date" />
        <SelectInput
          label="Priority Position"
          source="position"
          resettable
          choices={[
            { id: "before", name: "Before" },
            { id: "after", name: "After" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, record, dispatch, ...rest }: any) =>
            formData.position !== undefined && (
              <ReferenceInput
                label="Priority Anchor"
                source="priorityValue"
                reference="promotions"
                perPage={100000}
                validate={[required()]}
                style={{ width: "260px" }}
              >
                <SelectInput optionText="title" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, record, dispatch, ...rest }: any) => {
            if (!formData.upload) {
              return (
                // <Labeled label="Current Cover Image">
                //   <ImageField source="cover_img" {...rest} />
                // </Labeled>
                <ImagePreview record={record} />
              );
            }
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <BooleanInput
                source="active"
                label={formData.active ? "Active" : "Non Active"}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <ImageInput source="upload" label="Change Cover Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <RichTextInput source="body" />
      </SimpleForm>
    </Edit>
  );
};
const ImagePreview = (props: any) => {
  return (
    <div>
      {props.record.image ? (
        <Labeled label="Current Cover Image">
          <img
            src={props.record.image}
            alt="imgpreview"
            style={{ width: "300px" }}
          />
        </Labeled>
      ) : (
        "No Image"
      )}
    </div>
  );
};
