/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import client from "../Utils/client";
import { allExecutionLogsList } from "../Graphql/Executionlogs";
import moment from "moment";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import ReactJson from "react-json-view";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const headCells = [
  {
    id: "label",
    numeric: false,
    disablePadding: false,
    label: "Label",
  },
  { id: "thrown", numeric: false, disablePadding: false, label: "Status" },
  { id: "log", numeric: false, disablePadding: false, label: "Log" },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Updated at",
  },
];

function EnhancedTableHead(props: any) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props: any) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
  },
  paper: {
    width: "100%",
  },
  table: {
    width: "820px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  filter: {
    marginRight: "20px",
  },
  filterBox: {
    margin: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = React.useState(true);
  const [datafetch, setdata] = React.useState<any>({ data: [], count: 0 });
  const [successExport, setSuccessExport] = React.useState(false);
  const [selectLog, setSelectLog] = React.useState({});
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    // console.log("awal");
    // setCourse(window.location.pathname.split("/")[2]);
    fetchData();
  }, []);

  React.useEffect(() => {
    // console.log("change");
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    // console.log(idVendor);

    setloading(true);
    try {
      let query;
      query = {
        query: allExecutionLogsList,
        variables: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort: "created_at",
          order: "ASC",
          where: {
            transaction_id: { _eq: window.location.pathname.split("/")[2] },
            // _and: [
            //   { created_at: { _gte: moment(begin).format() } },
            //   { created_at: { _lte: moment(end).format() } },
            // ],
          },
        },
      };
      const gql = await client.gql.query(query);
      console.log(gql);
      setdata(gql.allExecutionLogsList);

      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = async (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSelectAllClick = async () => {};

  const handleChangeRowsPerPage = (event: any) => {
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectedLog = (data: any) => {
    setSelectLog(data);
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={successExport}
        autoHideDuration={3000}
        onClose={() => setSuccessExport(false)}
      >
        <Alert onClose={() => setSuccessExport(false)} severity="success">
          Success export Deposits, check Reports
        </Alert>
      </Snackbar>

      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <CircularProgress style={{ width: "75px", height: "75px" }} />
        </div>
      ) : (
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={datafetch.count}
              />
              <TableBody>
                {datafetch.data.map((row: any, index: Number) => {
                  const isItemSelected = false;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell id={labelId}>{row.label}</TableCell>
                      <TableCell id={labelId}>
                        {row.thrown ? <ClearIcon /> : <DoneIcon />}
                      </TableCell>
                      <TableCell id={labelId}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => selectedLog(row.log)}
                        >
                          <FindInPageIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell id={labelId}>
                        {moment(row.created_at).format("DD-MM-YYYY, h:mm:ss a")}
                      </TableCell>
                      <TableCell id={labelId}>
                        {moment(row.updated_at).format("DD-MM-YYYY, h:mm:ss a")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={datafetch.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle id="customized-dialog-title">Log Detail</DialogTitle>
            <DialogContent dividers>
              <ReactJson
                src={selectLog}
                collapsed={2}
                name="log"
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                collapseStringsAfterLength={50}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                close
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </div>
  );
}
