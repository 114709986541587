import React from "react";
import {
  AppBar,
  UserMenu,
  //  MenuItemLink
} from "react-admin";

import Typography from "@material-ui/core/Typography";
// import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import errorHandler from "../Utils/errorHandler";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
// import QRCode from "qrcode.react";
import Grid from "@material-ui/core/Grid";
import client from "../Utils/client";
// import forceLogout from "../utils/forceLogout";
// import Logo from "./Logo";
import { Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  text: {
    color: "black",
  },
});

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [uri, setUri] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [errorOtp, setErrorOtp] = React.useState(false);
  const [profile, setProfile] = React.useState({ email: "", roles: ["aaa"] });

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOtp("");
    setUri("");
    setOpenDialog(false);
  };

  const resetOtp = async () => {
    setLoading(true);
    const otp = await client.invokeApi("portal-reset-2fa", {
      mode: "GENERATE",
    });
    console.log(otp);
    setUri(otp.data.qrUri);
    setLoading(false);

    // if (otp.message === "Invalid Password!") {
    //   setPassword("");
    //   setOpenDialogConfirm(false);
    //   setOpenSnack(true);
    // } else {
    //   setPassword("");
    //   setOpenDialogConfirm(false);
    //   setOpenDialog(false);
    //   // setUri(otp.data);
    //   setShowQR(true);
    // }
  };

  const confirmOtp = async () => {
    setLoading(true);
    const confirmOtp = await client.invokeApi("portal-reset-2fa", {
      mode: "CONFIRM",
      code: otp,
    });
    console.log(confirmOtp);
    setLoading(false);

    if (confirmOtp.error === "[41] Invalid OTP code.") {
      setOtp("");
      setErrorOtp(true);
    } else {
      setOtp("");
      setUri("");
      setSuccess(true);
      setOpenDialog(false);
    }
  };

  const fetchProfile = async () => {
    // try {
    //   if (localStorage.getItem("MBaaS.auth.accessToken")) {
    //     const profile = await client.user.get();
    //     console.log(profile);
    //     if (profile.error) {
    //       await errorHandler(profile);
    //     } else {
    //       setProfile(profile.data);
    //     }
    //   }
    // } catch (error) {
    //   errorHandler(error);
    // }
    const profile: any = localStorage.getItem("profile");
    if (profile !== null) {
      setProfile(JSON.parse(profile));
    }
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  const CustomUserMenu = (props: any) => {
    return (
      <UserMenu {...props}>
        <MenuItem disabled className={classes.text}>
          {profile.email}
        </MenuItem>
        {/* <MenuItem disabled>{profile.email}</MenuItem> */}
        <MenuItem disabled className={classes.text}>
          {profile.roles[0]}
        </MenuItem>

        {/* <MenuItemLink
          to={"/profile"}
          // onClick={() => editProfile()}
          primaryText={"Edit Profile"}
          leftIcon={<EditIcon />}
        /> */}
      </UserMenu>
    );
  };

  return (
    <React.Fragment>
      <AppBar
        {...props}
        elevation={1}
        style={{ backgroundColor: "#F47820", color: "white" }}
        userMenu={<CustomUserMenu profile={profile} />}
      >
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        {/* <Logo /> */}
        <span className={classes.spacer} />
        <Tooltip title="Reset OTP" aria-label="barcode">
          <IconButton aria-label="Barcode" onClick={handleClickOpenDialog}>
            <FontAwesomeIcon icon={faQrcode} style={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </AppBar>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success confirm qrcode
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Reset OTP secret permission
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // style={{ height: "200px", marginTop: "200px" }}
          >
            {uri !== "" && <img src={uri} alt="qr" />}
            {uri === "" ? (
              <Button
                color="primary"
                variant="contained"
                // eslint-disable-next-line react/jsx-no-duplicate-props
                onClick={resetOtp}
                fullWidth
              >
                Generate QRcode
              </Button>
            ) : (
              <div style={{ margin: "0px 20px" }}>
                {errorOtp && (
                  <Alert severity="error" style={{ marginBottom: "10px" }}>
                    Invalid OTP code , please try again.
                  </Alert>
                )}

                <TextField
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  id="outlined-basic"
                  label="OTP"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  helperText="Insert otp code to confirm this qrcode , please scan before 3 minutes after you generate qrcode"
                />

                <Button
                  //   onClick={handleCloseDialog}
                  color="primary"
                  variant="contained"
                  disabled={otp === ""}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={confirmOtp}
                  fullWidth
                >
                  CONFIRM QRCODE OTP
                </Button>
              </div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomAppBar;
