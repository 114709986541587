/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import TextFieldMui from "@material-ui/core/TextField";
import client from "../Utils/client";
import { LexoRank } from "lexorank";
import { Save } from "@material-ui/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageIcon from "@material-ui/icons/Image";
import isProd from "../Utils/variabels";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import errorHandler from "../Utils/errorHandler";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OptsForm from "./OptsForm";
import InputsForm from "./InputsForm";
import ChipInput from "material-ui-chip-input";
import ReactJson from "react-json-view";
interface Props {
  items: any;
  refetch: any;
  parent_id: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface NewDataChild {
  icon: string;
  label: string;
  schema: string;
  opts?: Array<any>;
  inputs?: Array<any>;
  inquiryName?: string;
  defaultInquiryArgs?: any;
  category?: string;
  optGroups?: Array<string>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddNewParent = ({ items, refetch, parent_id }: Props) => {
  const [icon, setIcon] = React.useState<string>("");
  const [newData, setNewData] = React.useState<NewDataChild>({
    icon: "",
    label: "",
    schema: "",
  });

  const [label, setLabel] = React.useState<string>("");
  const [newImageFile, setNewImageFile] = React.useState<any>(null);
  const [nameFile, setNameFile] = React.useState("");
  const [cropper, setCropper] = React.useState<any>(null);
  const [imageBlob, setImageBlob] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState(0);
  const notifier = useNotify();
  const [value, setValue] = React.useState(0);

  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log(newValue);

    setValue(newValue);
  };

  const handleFileChange = (event: any) => {
    const name = event.target.files[0].name;
    setImageBlob(null);
    setNameFile(name);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };
  const uploadImage = async (childItem: any) => {
    cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toBlob(async (blob: any) => {
        try {
          const uploadResult = await client.storage.upload({
            file: blob,
            progressCallback: (progressVal: number) => setProgress(progressVal),
            params: {
              filename: `ProductLayout/${nameFile}`,
              bucket: isProd.publicBucket,
            },
          });
          console.log(uploadResult);
          const download = await client.storage.downloadUrl({
            params: {
              bucket: isProd.publicBucket,
            },
            fileId: uploadResult.data.id,
          });

          console.log(download);
          const urlImg = download.data.url.split("?");
          console.log(urlImg[0]);

          let newKey;
          if (childItem.length === 0) {
            newKey = LexoRank.middle().toString();
          } else {
            console.log(childItem);

            newKey = LexoRank.parse(childItem[childItem.length - 1].key)
              .genNext()
              .toString();
          }
          console.log(newKey);
          // add to table
          const table = client.getTable("product_layouts");
          const insert = await table.insert({
            value: {
              ...newData,
              icon: urlImg[0],
            },
            parent_id,
            key: newKey,
          });
          console.log(insert);

          //refresh
          await refetch();

          //cleanup
          setImageBlob(null);
          setNewData({
            icon: "",
            label: "",
            schema: "",
          });
          setLoading(false);
          setValue(0);
          notifier("Add New Child Success");
          // cleanup();
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
          // await forceLogout(e);
          // cleanup();
        }
      }, "image/png");
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log(newData);
    const childItem = items.filter((x: any) => x.id === parent_id);
    console.log(childItem);

    console.log("submit");
    await uploadImage(childItem[0].children.data);
  };

  const resetImageSelect = () => {
    setNewImageFile(null);
  };

  const submitCrop = () => {
    const test = cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toDataURL("image/png");
    setImageBlob(test);
    resetImageSelect();
  };

  const onChangeDetail = (e: any, name: string) => {
    // e.preventDefault();
    setNewData({
      ...newData,
      [name]: e.target.value,
    });
  };

  const setInputs = (data: any) => {
    setNewData({
      ...newData,
      inputs: data,
    });
  };

  const setOpts = (data: any) => {
    setNewData({
      ...newData,
      opts: data,
    });
  };

  const setOptGroups = (data: any) => {
    setNewData({
      ...newData,
      optGroups: data,
    });
  };

  const setDefaultInq = (data: any) => {
    setNewData({
      ...newData,
      defaultInquiryArgs: data,
    });
  };
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  const setSchema = (data: any) => {
    setNewData({
      icon: "",
      label: newData.label,
      schema: data.target.value,
    });
  };

  return (
    <div>
      <form onSubmit={submit}>
        <div
          style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "bold" }}
        >
          <Grid
            container
            direction="row"
            style={{ marginBottom: "20px" }}
            justify="space-between"
          >
            <Typography variant="h6">Add New Child</Typography>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              startIcon={loading ? "" : <Save />}
              disabled={loading || imageBlob === null}
            >
              {loading ? (
                <CircularProgress style={{ width: "20px", height: "20px" }} />
              ) : (
                "Save New Child"
              )}
            </Button>
          </Grid>
          <Divider style={{ marginTop: "10px" }} />
        </div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Detail" {...a11yProps(0)} />
            {newData.schema !== "" && <Tab label="Inputs" {...a11yProps(1)} />}

            {newData.schema !== "" && newData.schema !== "INQUIRY_FIRST" && (
              <Tab label="Options" {...a11yProps(2)} />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <Grid container direction="row" style={{ marginBottom: "20px" }}>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {imageBlob ? (
                  <img
                    src={imageBlob}
                    alt="current img"
                    style={{ width: "100px", height: "100px" }}
                  />
                ) : (
                  !newImageFile && (
                    <img
                      src={
                        "https://placehold.jp/30/F47820/FFFFFF/200x200.jpg?text=Select Image"
                      }
                      alt="current img"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )
                )}
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  required
                  onChange={(e) => handleFileChange(e)}
                  accept="image/*"
                />
                {!newImageFile && (
                  <div style={{ margin: "10px 0px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<ImageIcon />}
                      onClick={() => fileInputRef?.current?.click()}
                      size="small"
                    >
                      Select Image
                    </Button>
                  </div>
                )}
                {newImageFile && (
                  <div>
                    <Cropper
                      src={newImageFile}
                      style={{
                        width: "200px",
                        height: "200px",
                        alignSelf: "center",
                      }}
                      // Cropper.js options
                      aspectRatio={100 / 100}
                      viewMode={1}
                      guides={true}
                      scalable={true}
                      onInitialized={(cropperInstance) =>
                        setCropper(cropperInstance)
                      }
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "15px",
                        textAlign: "center",
                        width: "200px",
                        overflowX: "auto",
                      }}
                    >
                      {nameFile}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ImageIcon />}
                        onClick={() => fileInputRef?.current?.click()}
                        size="small"
                      >
                        Select Image
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => submitCrop()}
                        size="small"
                        startIcon={<Save />}
                        style={{ marginTop: "10px" }}
                      >
                        Save Image
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Schema</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={newData.schema}
                    defaultValue="PRODUCT_FIRST"
                    fullWidth
                    required
                    onChange={(e) => setSchema(e)}
                  >
                    <MenuItem value="PRODUCT_FIRST">PRODUCT_FIRST</MenuItem>
                    <MenuItem value="INQUIRY_FIRST">INQURY_FIRST</MenuItem>
                    <MenuItem value="PATTERN_FIRST">PATTERN_FIRST</MenuItem>
                  </Select>
                </FormControl>
                <TextFieldMui
                  label="Label"
                  value={newData.label}
                  required
                  fullWidth
                  // helperText="Label name for parents"
                  onChange={(e) => {
                    onChangeDetail(e, "label");
                  }}
                  style={{ marginBottom: "20px" }}
                />
                {newData.schema === "INQUIRY_FIRST" && (
                  <TextFieldMui
                    label="Category"
                    value={newData.category}
                    fullWidth
                    required
                    onChange={(e) => {
                      onChangeDetail(e, "category");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                )}
                {newData.schema !== "" && newData.schema !== "PATTERN_FIRST" && (
                  <TextFieldMui
                    label="Inquiry Name"
                    value={newData.inquiryName}
                    fullWidth
                    required={newData.schema === "INQUIRY_FIRST"}
                    onChange={(e) => {
                      onChangeDetail(e, "inquiryName");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                )}
                {newData.schema !== "" && newData.schema !== "PATTERN_FIRST" && (
                  <div>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", color: "grey" }}
                    >
                      Default Inquiry Arg
                    </Typography>
                    <ReactJson
                      src={newData.defaultInquiryArgs || {}}
                      name="defaultInquiryArgs"
                      collapsed={1}
                      onAdd={(e) => setDefaultInq(e.updated_src)}
                      onEdit={(e) => setDefaultInq(e.updated_src)}
                      onDelete={(e) => setDefaultInq(e.updated_src)}
                      enableClipboard={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      collapseStringsAfterLength={50}
                    />
                  </div>
                )}

                {newData.schema === "PATTERN_FIRST" && (
                  <ChipInput
                    fullWidth
                    placeholder="enter to insert option"
                    label="Option Groups"
                    onKeyDown={(e) => handleKey(e)}
                    value={newData.optGroups}
                    InputProps={{ className: "optsgroups" }}
                    style={{ marginBottom: "20px" }}
                    onChange={(e) => {
                      console.log(e);

                      setOptGroups(e);
                    }}
                    onDelete={(chip, index) => {
                      const data = newData.optGroups || [];
                      data.splice(index, 1);
                      setOptGroups(data);
                    }}
                    onAdd={(e) => {
                      const data = newData.optGroups || [];
                      setOptGroups(data.concat(e));
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          inpts
          <InputsForm
            data={newData.inputs || []}
            setData={(e: any) => setInputs(e)}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          opts
          <OptsForm
            data={newData.opts || []}
            setData={(e: any) => setOpts(e)}
            schema={newData.schema}
          />
        </TabPanel>
        {/* <ReactJson
          src={newData}
          collapsed={2}
          enableClipboard={true}
          displayDataTypes={false}
          displayObjectSize={false}
          collapseStringsAfterLength={50}
        /> */}
      </form>
    </div>
  );
};

export default AddNewParent;
