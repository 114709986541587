const updatemany = async (resource: any, params: any) => {
    console.log("updatemany");
    const data = {
      data: [],
      total: 0,
    };
    return data;
  };
  
  export default updatemany;
  