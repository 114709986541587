import gql from "graphql-tag";

export const allProductLayoutsList = gql`
  query allProductLayoutsList(
    $limit: Int
    $offset: Int
    $sort: ProductLayoutsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: ProductLayoutsWhereInput
  ) {
    allProductLayoutsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        key
        value
        children: product_layouts_parent_id_list(sort: $sort, order: $order) {
          data {
            id
            parent_id
            value
            key
          }
          count
        }
      }
      count
    }
  }
`;

export const getProductLayoutsById = gql`
  query getProductLayoutsById($id: UUID!) {
    getProductLayoutsById(id: $id) {
      id
      created_at
      updated_at
      key
      value
    }
  }
`;
