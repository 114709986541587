import client from "../Utils/client";
const errorHandler = async (e: any) => {
  console.log(e);
  console.log(e.errors[0].title);

  if (e.error?.title === "refresh token expired") {
    console.log("rt exp");
    await logout();
  } else if (e.errors && e.errors[0].title === "jwt malformed") {
    console.log("jwt malformed");
    await logout();
  } else if (e.errors && e.errors[0].title === "invalid token") {
    console.log("invalid token");
    await logout();
  } else if (
    e.response &&
    e.response?.data?.errors[0]?.title === "refresh token expired"
  ) {
    console.log("jwt malformed");
    await logout();
  } else if (e.error?.title === "The token has been revoked.") {
    console.log("jwt malformed");
    await logout();
  } else if (e.errors && e.errors[0].title === "The token has been revoked.") {
    console.log("invalid token");
    await logout();
  } else {
    console.log("other error");
    return Promise.reject({
      status: 500,
      message: e.message,
      json: e,
    });
  }
};

const logout = async () => {
  const logout = await client.user.logout();
  console.log(logout);
  window.location.replace("/login");
};

export default errorHandler;
