import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  required,
  EditButton,
  FunctionField,
  BooleanField,
  SelectInput,
  Edit,
  BooleanInput,
  useNotify,
  DateField,
  FormDataConsumer,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  // SelectInput,
} from "react-admin";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import client from "../Utils/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const UserList = (props: any) => {
  const [reset, setReset] = React.useState<String>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const isAdmin = props.permissions === "Administrator";
  const ResetPass = ({ record }: any) => {
    return (
      <Button
        variant="contained"
        size="small"
        startIcon={record.email === reset ? "" : <LockOpenIcon />}
        color="primary"
        disabled={record.email === reset}
        onClick={() => resetPassRequest(record.email)}
      >
        {record.email === reset ? (
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        ) : (
          "Reset"
        )}
      </Button>
    );
  };
  ResetPass.defaultProps = { label: "Password", addLabel: true };

  const ListActions = (props: any) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      // currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      basePath,
      // selectedIds,
      showFilter,
      // total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {isAdmin && <CreateButton basePath={basePath} />}
        {/* <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        /> */}
      </TopToolbar>
    );
  };

  const resetPassRequest = async (email: String) => {
    console.log(email);
    setReset(email);
    const forget = await client.auth.forgotPassword(email);
    console.log(forget);
    setOpen(true);
    setReset("");
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <List
      {...props}
      filters={<PostFilter />}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      <React.Fragment>
        <Datagrid>
          <TextField source="name" />
          <TextField source="email" />
          <Role />
          <BooleanField source="verified" />
          <FunctionField
            label="Active"
            render={(record: any) =>
              record.deactivated_at === null ? <DoneIcon /> : <ClearIcon />
            }
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />

          {isAdmin && <ResetPass />}
          {isAdmin && <EditButton />}
        </Datagrid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Success reset password, need check email user
          </Alert>
        </Snackbar>
      </React.Fragment>
    </List>
  );
};

const Role = ({ record }: any) => {
  return (
    <div>
      {record && record.roles
        ? record.roles.length === 0
          ? "-"
          : record.roles[0]
        : "-"}
    </div>
  );
};
Role.defaultProps = { label: "Role", addLabel: true };

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" validate={[required()]} type="email" />
      <TextInput source="name" validate={[required()]} />
      <SelectInput
        source="roles"
        choices={[
          { id: "Staff", name: "Staff" },
          { id: "Administrator", name: "Administrator" },
        ]}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: any) => {
  const notify = useNotify();

  const onFailure = (error: any) => {
    console.log(error.response);
    if (error.response.data.errors[0].code === "PERMISSION_DENIED") {
      notify(`Only Administrator can edit users`);
    } else {
      notify(`Could not edit users`);
    }
  };
  return (
    <Edit title="Edit User" onFailure={onFailure} undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="Email" source="email" />
        <TextInput source="name" validate={[required()]} />
        <BooleanInput source="verified" label="verified user" />
        <SelectInput
          source="roles"
          choices={[
            { id: "Staff", name: "Staff" },
            { id: "Administrator", name: "Administrator" },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <BooleanInput
                source="active"
                label={formData.active ? "Active" : "Non Active"}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search by email" source="email" alwaysOn />
  </Filter>
);
