import client from "../../Utils/client";
import { createUsers } from "../../Graphql/Users";
import { createPromotions } from "../../Graphql/Promotion";
import errorHandler from "../../Utils/errorHandler";
import isProd from "../../Utils/variabels";
import dataProvider from "../dataProvider";
import { LexoRank } from "lexorank";
const create = async (resource: any, params: any) => {
  console.log("create");
  let data;
  try {
    if (resource === "users") {
      const mutation = {
        mutation: createUsers,
        variables: {
          input: { ...params.data, roles: params.data.roles.split(" ") },
        },
      };
      const response = await client.gql.mutation(mutation);
      console.log(response);
      const forget = await client.auth.forgotPassword(params.data.email);
      console.log(forget);

      data = {
        data: response.createUsers[0],
      };
    } else if (resource === "promotions") {
      console.log(params.data);
      let priority;

      // set priority for new proomo
      if (params.data.position) {
        const get = await dataProvider.getOne(resource, {
          id: params.data.priorityValue,
        });
        const referenceRank = get.data.priority;
        console.log(referenceRank);

        if (params.data.position === "after") {
          const after = LexoRank.parse(referenceRank).genNext().toString();
          console.log(after);
          priority = after;
        } else if (params.data.position === "before") {
          const before = LexoRank.parse(referenceRank).genPrev().toString();
          console.log(before);
          priority = before;
        }
      } else {
        priority = LexoRank.middle().toString();
      }

      const upload = await client.storage.upload({
        params: {
          filename: params.data.image.title,
          bucket: isProd.publicBucket,
          expire: "600",
        },
        file: params.data.image.rawFile,
        progressCallback: (progressVal: any) => console.log(progressVal),
      });
      console.log(upload);
      const download = await client.storage.downloadUrl({
        params: {
          bucket: isProd.publicBucket,
        },
        fileId: upload.data.id,
      });

      console.log(download);
      const urlImg = download.data.url.split("?");

      delete params.data.position;
      delete params.data.priorityValue;
      const mutation = {
        mutation: createPromotions,
        variables: {
          input: { ...params.data, image: urlImg[0], priority },
        },
      };
      const response = await client.gql.mutation(mutation);
      console.log(response);
      data = {
        data: response.createPromotions[0],
      };
    } else {
      data = {
        data: [],
        total: 0,
      };
    }
    return data;
  } catch (error) {
    await errorHandler(error);
  }
};

export default create;
