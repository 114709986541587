import errorHandler from "../../Utils/errorHandler";

const getManyReference = async (resource: any, params: any) => {
  console.log("getManyReference");
  try {
    const data = {
      data: [],
      total: 0,
    };
    return data;
  } catch (error) {
    await errorHandler(error);
  }
};

export default getManyReference;
