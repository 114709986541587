/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, makeStyles, Divider } from "@material-ui/core";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import client from "../Utils/client";
import getParam from "../Utils/getParam";
import CircularProgress from "@material-ui/core/CircularProgress";
const loginStyles: any = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    width: "400px",
    minHeight: "530px",
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "20px",
    textAlign: "center",
  },
  buttonGroup: {
    margin: "0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    marginBottom: "20px",
  },
  button2: {
    marginBottom: "20px",
    backgroundColor: "white",
    color: "black !important",
    fontWeight: "bold",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: "#efefef",
      color: "black",
    },
  },
  text: {
    textAlign: "center",
    margin: "20px 0px",
    fontWeight: "bold",
  },
  text2: {
    textAlign: "center",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#F47820",
    cursor: "pointer",
  },
  input: {
    marginBottom: "20px",
  },
  logo: {
    width: "300px",
    height: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
  },
  google: {
    width: "25px",
  },
  divider: {
    marginBottom: "20px",
  },
  error: {
    marginBottom: "10px",
  },
  success: {
    marginBottom: "10px",
  },
  circular: {
    width: "10px",
    height: "10px",
  },
}));

export default function Login() {
  const classes = loginStyles();
  const [mode, setMode] = React.useState("login");
  const [errorForget, setErrorForget] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailReset, setEmailReset] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const login = async (event: any) => {
    event.preventDefault();
    setErrorLogin(false);
    setLoading(true);
    console.log("login");
    const login = await client.auth.login("local", {
      email: username,
      password,
    });
    console.log(login);

    if (login.error) {
      setLoading(false);
      setErrorLogin(true);
    } else {
      localStorage.setItem("permission", login.data.roles[0]);
      const { data: profileData } = await client.user.get();

      localStorage.setItem("profile", JSON.stringify(profileData));
      setLoading(false);
      setErrorLogin(false);
      window.location.replace("/");
    }
  };

  const forget = async (event: any) => {
    event.preventDefault();
    setErrorForget(false);
    setLoading(true);
    setSuccess(false);
    const forget = await client.auth.forgotPassword(emailReset);
    console.log(forget);

    if (forget !== null) {
      setLoading(false);
      setErrorForget(true);
      setEmailReset("");
    } else {
      setLoading(false);
      setSuccess(true);
      setEmailReset("");
    }
  };
  const backLogin = () => {
    setErrorForget(false);
    setSuccess(false);
    setEmailReset("");
    setMode("login");
  };

  const googleRequest = async () => {
    const req = await client.auth.oauthRedirect(
      "google",
      `${window.location.origin}/login`
    );
    console.log(req);
  };

  React.useEffect(() => {
    googleLogin();
    if (window.location.hostname === "localhost") {
      setUsername("admin@mylib.id");
      setPassword("12QWaszx");
    }
  }, []);

  async function googleLogin() {
    const code = getParam("code");
    if (!code) {
      return;
    }
    const loginRes = await client.auth.login("google", {
      callback: "http://localhost:8080/login",
      code,
    });

    console.log(loginRes);

    if (!loginRes.data) return console.log("error login");
    if (loginRes.data.type === "LoginExisting") {
      console.log("loginexist");
      const role = await client.user.get();
      localStorage.setItem("permission", role.data.roles[0]);
      window.location.assign("/");
      return;
    }

    const loginAttr = loginRes.data;

    //   Create a new user based on the social account.
    const registerRes = await client.auth.register("google", {
      oauthKey: loginAttr.oauthKey,
      email: loginAttr.email,
      extras: {
        name: loginAttr.name,
      },
    });

    console.log(registerRes);

    if (!registerRes.data) return console.log("error register");
    if (registerRes.data.type === "Profile") {
      client.auth.redoOAuth("google");
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div style={{ textAlign: "center" }}>
          <img src="/logo.png" alt="logo" className={classes.logo} />{" "}
        </div>
        {mode === "login" ? (
          <div className="login">
            <div className={classes.text}>Log in to your account</div>

            <form onSubmit={login}>
              <div className={classes.buttonGroup}>
                {errorLogin && (
                  <Alert severity="error" className={classes.error}>
                    Invalid user
                  </Alert>
                )}

                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className={classes.input}
                  required
                />
                <FormControl className={classes.input} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={110}
                  />
                </FormControl>
              </div>
              <div className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.circular}
                      style={{ width: "25px", height: "25px" }}
                    />
                  ) : (
                    "LOGIN"
                  )}
                </Button>
                <Divider />
                <div className={classes.text}>OR</div>
                <Button
                  variant="contained"
                  className={classes.button2}
                  onClick={googleRequest}
                  startIcon={
                    <img
                      src="https://img.icons8.com/clouds/2x/google-logo.png"
                      alt="google"
                      className={classes.google}
                    />
                  }
                >
                  CONTINUE WITH GOOGLE
                </Button>
                <div
                  className={classes.text2}
                  onClick={() => setMode("forget")}
                >
                  Forget password ? Reset your password here.
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="forget">
            <div className={classes.text}>Recovery Account </div>

            <form onSubmit={forget}>
              <div className={classes.buttonGroup}>
                {errorForget && (
                  <Alert severity="error" className={classes.error}>
                    Email invalid !
                  </Alert>
                )}
                {success && (
                  <Alert severity="success" className={classes.success}>
                    Reset password link sent to your email
                  </Alert>
                )}

                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  required
                  value={emailReset}
                  onChange={(e) => setEmailReset(e.target.value)}
                  className={classes.input}
                />
              </div>
              <div className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  type="submit"
                  className={classes.button}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.circular}
                      style={{ width: "25px", height: "25px" }}
                    />
                  ) : (
                    "Forgot password"
                  )}
                </Button>
                <Divider className={classes.divider} />
                <div className={classes.text2} onClick={backLogin}>
                  Back to Login
                </div>
              </div>
            </form>
          </div>
        )}
      </Card>
    </div>
  );
}
