import gql from "graphql-tag";

export const allReportsList = gql`
  query allReportsList(
    $limit: Int
    $offset: Int
    $sort: ReportsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: ReportsWhereInput
  ) {
    allReportsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        created_by
        filename
        status
        type
        remark
        created_by_data {
          id
          name
          email
        }
      }
      count
    }
  }
`;
