import React from "react";
import { Button, CircularProgress, Divider, Grid } from "@material-ui/core";
import TextFieldMui from "@material-ui/core/TextField";
import client from "../Utils/client";
import { Delete, Save } from "@material-ui/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageIcon from "@material-ui/icons/Image";
import isProd from "../Utils/variabels";
import { useNotify } from "react-admin";
import forceLogout from "../Utils/errorHandler";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import errorHandler from "../Utils/errorHandler";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OptsForm from "./OptsForm";
import InputsForm from "./InputsForm";
import ChipInput from "material-ui-chip-input";
import ReactJson from "react-json-view";

interface Props {
  item: any;
  refetch: any;
  setItem: any;
  items: any;
  setItems: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditParent = ({ item, refetch, setItem, items, setItems }: Props) => {
  // const [icon, setIcon] = React.useState<any>(null);
  // const [label, setLabel] = React.useState<string>("");
  const [newImageFile, setNewImageFile] = React.useState<any>(null);
  const [nameFile, setNameFile] = React.useState("");
  const [cropper, setCropper] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = React.useState(0);
  const notifier = useNotify();
  const [value, setValue] = React.useState(0);

  const fileInputRef = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    // console.log("change");
    setDataInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log(newValue);

    setValue(newValue);
  };
  const setDataInit = () => {
    // setIcon(item.value.icon);
    // setLabel(item.value.label);
    setNewImageFile(null);
  };

  const deleteData = async () => {
    setLoading(true);
    try {
      const table = client.getTable("product_layouts");
      const deleteData = await table.del({
        id: items.id,
      });

      console.log(deleteData);
      setItems([]);

      //refresh
      notifier("Delete Child Success");
      await refetch();
      setLoading(false);
    } catch (e) {
      console.log(e.response.data);
      setLoading(false);
      await errorHandler(e);
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log("submit");
    // create new key

    if (nameFile === "") {
      // add to table
      console.log("BIASA");

      try {
        const table = client.getTable("product_layouts");
        const update = await table.update({
          id: items.id,
          data: items,
        });
        console.log(update);

        //refresh
        await refetch();
        setLoading(false);
      } catch (e) {
        console.log(e);
        await forceLogout(e);
      }
    } else {
      await uploadImage();
    }
  };

  const uploadImage = async () => {
    cropper
      .getCroppedCanvas({ width: "200", height: "200" })
      .toBlob(async (blob: any) => {
        console.log(blob);
        try {
          const uploadResult = await client.storage.upload({
            file: blob,
            progressCallback: (progressVal: number) => setProgress(progressVal),
            params: {
              filename: `ProductLayout/${nameFile}`,
              bucket: isProd.publicBucket,
            },
          });
          console.log(uploadResult);
          const download = await client.storage.downloadUrl({
            params: {
              bucket: isProd.publicBucket,
            },
            fileId: uploadResult.data.id,
          });

          console.log(download);
          const urlImg = download.data.url.split("?");
          console.log(urlImg[0]);

          // const reform = {
          //   ...item.value,
          //   icon: urlImg[0],
          //   label,
          // };
          // console.log(reform);

          // add to table
          const table = client.getTable("product_layouts");
          const update = await table.update({
            id: items.id,
            data: {
              ...items,
              value: { ...item, icon: urlImg[0] },
            },
          });
          console.log(update);
          setItem({
            ...item,
            icon: urlImg[0],
          });
          //refresh
          await refetch();

          //cleanup
          setLoading(false);
          notifier("Edit Child Success");
          // cleanup();
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
          await forceLogout(e);
          // cleanup();
        }
      }, "image/png");
  };

  const handleFileChange = (event: any) => {
    const name = event.target.files[0].name;
    setItem({
      ...item,
      icon: null,
    });
    setNameFile(name);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };

  const resetImageSelect = () => {
    setNewImageFile(null);
  };

  const submitCrop = () => {
    const test = cropper
      .getCroppedCanvas({ width: "200", height: "200" })
      .toDataURL("image/png");
    // setIcon(test);
    setItem({
      ...item,
      icon: test,
    });
    resetImageSelect();
  };

  const onChangeDetail = (e: any, name: string) => {
    // e.preventDefault();
    setItem({
      ...item,
      [name]: e.target.value,
    });
  };

  const setInputs = (data: any) => {
    setItem({
      ...item,
      inputs: data,
    });
  };

  const setOpts = (data: any) => {
    setItem({
      ...item,
      opts: data,
    });
  };

  const setOptGroups = (data: any) => {
    setItem({
      ...item,
      optGroups: data,
    });
  };

  const setDefaultInq = (data: any) => {
    setItem({
      ...item,
      defaultInquiryArgs: data,
    });
  };
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  const setSchema = (data: any) => {
    console.log(item.icon);

    setItem({
      icon: item.icon,
      label: item.label,
      schema: data.target.value,
    });
  };

  return (
    <div>
      <form onSubmit={submit}>
        <div
          style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "bold" }}
        >
          <Grid
            container
            direction="row"
            style={{ marginBottom: "20px" }}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h6">Edit Child</Typography>
            </Grid>
            <Grid item>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CircularProgress
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "40px",
                    }}
                  />
                </div>
              ) : (
                <Grid container direction="row" justify="flex-end">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{ marginRight: "10px" }}
                    disabled={loading || newImageFile}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Delete />}
                    onClick={deleteData}
                    disabled={loading}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "10px" }} />
        </div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Detail" {...a11yProps(0)} />
            {item.schema !== "" && <Tab label="Inputs" {...a11yProps(1)} />}

            {item.schema !== "" && item.schema !== "INQUIRY_FIRST" && (
              <Tab label="Options" {...a11yProps(2)} />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <Grid container direction="row" style={{ marginBottom: "20px" }}>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {item.icon !== null && (
                  <img
                    src={
                      item.icon === ""
                        ? "https://placehold.jp/30/F47820/FFFFFF/100x100.jpg?text=MAI-MAI"
                        : item.icon
                    }
                    alt="current img"
                    style={{ width: "100px", height: "100px" }}
                  />
                )}
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={(e) => handleFileChange(e)}
                  accept="image/*"
                />
                {!newImageFile && (
                  <div style={{ margin: "10px 0px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<ImageIcon />}
                      onClick={() => fileInputRef?.current?.click()}
                      size="small"
                    >
                      Select Image
                    </Button>
                  </div>
                )}
                {newImageFile && (
                  <div>
                    <Cropper
                      src={newImageFile}
                      style={{
                        width: "200px",
                        height: "200px",
                        alignSelf: "center",
                      }}
                      // Cropper.js options
                      aspectRatio={100 / 100}
                      viewMode={1}
                      guides={true}
                      scalable={true}
                      onInitialized={(cropperInstance) =>
                        setCropper(cropperInstance)
                      }
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "15px",
                        textAlign: "center",
                        width: "200px",
                        overflowX: "auto",
                      }}
                    >
                      {nameFile}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ImageIcon />}
                        onClick={() => fileInputRef?.current?.click()}
                        size="small"
                      >
                        Select Image
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => submitCrop()}
                        style={{ marginTop: "10px" }}
                        size="small"
                        startIcon={<Save />}
                      >
                        Save Image
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Schema</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item.schema}
                    defaultValue="PRODUCT_FIRST"
                    fullWidth
                    required
                    onChange={(e) => setSchema(e)}
                  >
                    <MenuItem value="PRODUCT_FIRST">PRODUCT_FIRST</MenuItem>
                    <MenuItem value="INQUIRY_FIRST">INQURY_FIRST</MenuItem>
                    <MenuItem value="PATTERN_FIRST">PATTERN_FIRST</MenuItem>
                  </Select>
                </FormControl>
                <TextFieldMui
                  label="Label"
                  value={item.label}
                  required
                  fullWidth
                  onChange={(e) => {
                    onChangeDetail(e, "label");
                  }}
                  style={{ marginBottom: "10px" }}
                />
                {item.schema === "INQUIRY_FIRST" && (
                  <TextFieldMui
                    label="Category"
                    value={item.category}
                    fullWidth
                    required
                    onChange={(e) => {
                      onChangeDetail(e, "category");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                )}
                {item.schema !== "" && item.schema !== "PATTERN_FIRST" && (
                  <TextFieldMui
                    label="Inquiry Name"
                    value={item.inquiryName || ""}
                    fullWidth
                    required={item.schema === "INQUIRY_FIRST"}
                    onChange={(e) => {
                      onChangeDetail(e, "inquiryName");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                )}
                {item.schema !== "" && item.schema !== "PATTERN_FIRST" && (
                  <div>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", color: "grey" }}
                    >
                      Default Inquiry Arg
                    </Typography>
                    <ReactJson
                      src={item.defaultInquiryArgs || {}}
                      name="defaultInquiryArgs"
                      collapsed={1}
                      onAdd={(e) => setDefaultInq(e.updated_src)}
                      onEdit={(e) => setDefaultInq(e.updated_src)}
                      onDelete={(e) => setDefaultInq(e.updated_src)}
                      enableClipboard={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      collapseStringsAfterLength={50}
                    />
                  </div>
                )}

                {item.schema === "PATTERN_FIRST" && (
                  <ChipInput
                    fullWidth
                    placeholder="enter to insert option"
                    label="Option Groups"
                    onKeyDown={(e) => handleKey(e)}
                    value={item.optGroups}
                    InputProps={{ className: "optsgroups" }}
                    style={{ marginBottom: "20px" }}
                    onChange={(e) => {
                      console.log(e);

                      setOptGroups(e);
                    }}
                    onDelete={(chip, index) => {
                      const data = item.optGroups || [];
                      data.splice(index, 1);
                      setOptGroups(data);
                    }}
                    onAdd={(e) => {
                      const data = item.optGroups || [];
                      setOptGroups(data.concat(e));
                    }}
                  />
                )}
                {/* <ReactJson
                  src={item}
                  collapsed={2}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  collapseStringsAfterLength={50}
                /> */}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InputsForm
            data={item.inputs || []}
            setData={(e: any) => setInputs(e)}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <OptsForm
            data={item.opts || []}
            setData={(e: any) => setOpts(e)}
            schema={item.schema}
          />
        </TabPanel>
      </form>
    </div>
  );
};

export default EditParent;
