import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import client from "../Utils/client";
import isProd from "../Utils/variabels";
export const LedgerList = (props: any) => {
  const ReportStyle = (record: any, index: number) => ({
    backgroundColor:
      record.status === "FAILED"
        ? "#ffcdd2"
        : record.status === "DONE"
        ? "#dcedc8"
        : "",
  });

  const DownloadButton = ({ record }: any) => {
    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        startIcon={<GetAppIcon />}
        disabled={record.status !== "DONE"}
        onClick={() => downloadFile(record)}
      >
        FILE
      </Button>
    );
  };

  const downloadFile = async (record: any) => {
    console.log(record);
    const download = await client.storage.downloadUrl({
      params: {
        bucket: isProd.privateBucket,
      },
      fileId: record.filename,
    });
    console.log(download);
    window.open(download.data.url, "_blank");
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid rowStyle={ReportStyle}>
        <TextField source="filename" label="Filename" />
        <TextField source="created_by_data.name" label="Created by" />
        <TextField source="status" />
        <TextField source="type" />
        <FunctionField
          label="Remark"
          render={(record: any) =>
            record.remark === null ? "-" : record.remark
          }
        />
        <DateField locales="en-TT" source="created_at" showTime />
        <DateField locales="en-TT" source="updated_at" showTime />
        <DownloadButton />
      </Datagrid>
    </List>
  );
};
