import gql from "graphql-tag";

export const allExecutionLogsList = gql`
  query allExecutionLogsList(
    $limit: Int
    $offset: Int
    $sort: ExecutionLogsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: ExecutionLogsWhereInput
  ) {
    allExecutionLogsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        label
        transaction_id
        deposit_id
        log
        thrown
      }
      count
    }
  }
`;
