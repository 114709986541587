import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
  // CreateButton,
  // ExportButton,
  TextInput,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  Show,
  SimpleShowLayout,
  FunctionField,
} from "react-admin";
import Drawer from "@material-ui/core/Drawer";
import exportFile from "../Utils/ExportFile";
import GetAppIcon from "@material-ui/icons/GetApp";
// @ts-ignore
import Button from "@material-ui/core/Button";
import moment from "moment";
import client from "../Utils/client";
import ReactJson from "react-json-view";
import ExecutionLogs from "../Components/executionLogs";
import { Refresh } from "@material-ui/icons";
import { AppBar, Chip, Snackbar, Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TabMat from "@material-ui/core/Tab";
function a11yProps(index: Number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const TransactionList = (props: any) => {
  // const isAdmin = props.permissions === "Administrator";
  const [open, setOpen] = React.useState(false);
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [successExport, setSuccessExport] = React.useState(false);

  const ListActions = (props: any) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      // currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      // basePath,
      // selectedIds,
      showFilter,
      // total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {/* <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        /> */}
        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={() =>
            exportFile(filterValues, "TRANSACTION", setSuccessExport)
          }
        >
          Export
        </Button>
      </TopToolbar>
    );
  };

  const fromData = moment().subtract(30, "days").format();
  const toData = moment().format();
  const TransStyle = (record: any, index: number) => ({
    backgroundColor:
      record.status === "FAILED"
        ? "#ffcdd2"
        : record.status === "SUCCESS"
        ? "#dcedc8"
        : "",
  });
  type Anchor = "top" | "left" | "bottom" | "right";

  const toggleDrawer = (anchor: Anchor, open: boolean, props?: any) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    if (!open) {
      props.history.goBack();
    }
    setOpen(open);
  };

  const recheckStatus = async (record: any) => {
    setLoadingStatus(true);
    const resync = await client.invokeApi("portal-resync-order", {
      orderId: record.order_id,
    });
    console.log(resync);
    setLoadingStatus(false);
    if (resync.data === "portal-resync-order") {
      setSuccess(true);
    } else {
      console.log("error");
    }
  };

  const TransactionShow = (props: any) => {
    const propsEdit = { ...props, id: window.location.pathname.split("/")[2] };
    const [value2, setValue2] = React.useState(0);

    const ButtonMenu = ({ record }: any) => {
      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div>
              {record.order_id && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginRight: "10px", color: "white" }}
                  startIcon={loadingStatus ? "" : <Refresh />}
                  onClick={() => recheckStatus(record)}
                >
                  status
                </Button>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    };

    const StatusPurchase = ({ record }: any) => {
      return (
        <ReactJson
          src={record.status_details}
          collapsed={0}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          collapseStringsAfterLength={50}
        />
      );
    };
    StatusPurchase.defaultProps = { label: "Status Details", addLabel: true };
    const handleChange2 = (event: any, newValue: any) => {
      setValue2(newValue);
    };
    return (
      <Drawer
        anchor="right"
        open={open}
        style={{ padding: "50px" }}
        onClose={toggleDrawer("right", false, props)}
      >
        <AppBar
          position="static"
          style={{ marginBottom: "0px", width: "850px" }}
        >
          <Tabs
            value={value2}
            onChange={handleChange2}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <TabMat label="Details" {...a11yProps(0)} />

            <TabMat label="Execution logs" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        {value2 === 0 && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <Show {...propsEdit} title=" ">
              <SimpleShowLayout>
                <ButtonMenu />
                <TextField source="order_id" label="Order ID" />
                <TextField source="vendor_id_data.name" label="Vendor" />
                <TextField source="vendor_id_data.email" label="Vendor email" />
                <TextField source="product_snapshot.name" label="Product" />
                <TextField source="target" />
                <TextField source="price" label="Price" />
                <FunctionField
                  label="Status"
                  render={(record: any) => (
                    <Chip
                      label={record.status}
                      style={{
                        backgroundColor:
                          record.status === "FAILED"
                            ? "#f9d9d9"
                            : record.status === "SUCCESS"
                            ? "#bfffbf"
                            : "#a0c9ff",
                      }}
                    />
                  )}
                />
                <StatusPurchase />
                <DateField locales="en-TT" source="created_at" showTime />
                <DateField locales="en-TT" source="updated_at" showTime />
              </SimpleShowLayout>
            </Show>
          </div>
        )}
        {value2 === 1 && (
          <div>
            <ExecutionLogs />
          </div>
        )}
        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={() => setSuccess(false)} severity="success">
            Success refresh status
          </Alert>
        </Snackbar>
      </Drawer>
    );
  };
  return (
    <React.Fragment>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<TransFilter />}
        actions={<ListActions />}
        sort={{ field: "created_at", order: "DESC" }}
        filterDefaultValues={{
          from: fromData,
          to: toData,
        }}
      >
        <Datagrid
          rowClick="show"
          rowStyle={TransStyle}
          onClick={toggleDrawer("right", true)}
        >
          <TextField
            source="order_id"
            label="Order ID"
            style={{ fontWeight: "bold" }}
          />
          <TextField source="vendor_id_data.name" label="Vendor" />
          {/* <TextField source="vendor_id_data.email" label="Vendor email" /> */}
          <TextField source="product_snapshot.name" label="Product" />
          <TextField source="target" />
          <TextField source="price" label="Price" />
          <TextField source="status" />
          <DateField locales="en-TT" source="created_at" showTime />
          <DateField locales="en-TT" source="updated_at" showTime />
        </Datagrid>
      </List>
      <TransactionShow {...props} />
      <Snackbar
        open={successExport}
        autoHideDuration={3000}
        onClose={() => setSuccessExport(false)}
      >
        <Alert onClose={() => setSuccessExport(false)} severity="success">
          Success export Transactions, check Reports
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

const TransFilter = (props: any) => (
  <div>
    <Filter {...props} style={{ paddingTop: "10px", paddingBottom: "5px" }}>
      <DateInput source="from" label="Begin" alwaysOn />
      <DateInput source="to" label="End" alwaysOn />
      <ReferenceInput
        label="Vendor"
        source="vendor_id"
        reference="users"
        perPage={100000}
        alwaysOn
        filter={{ roles: "Vendor" }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="status"
        alwaysOn
        choices={[
          { id: "SUCCESS", name: "SUCCESS" },
          { id: "PAID", name: "PAID" },
          { id: "FAILED", name: "FAILED" },
        ]}
      />
      <TextInput label="Target" source="target" alwaysOn />
      <TextInput label="Order ID" source="order_id" alwaysOn />
    </Filter>
  </div>
);
