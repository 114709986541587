import "./App.css";
// @ts-ignore
import { Admin, Resource } from "react-admin";
import Dashboard from "./Pages/Dashboard";
import dataProviders from "./Provider/dataProvider";
import { createBrowserHistory as createHistory } from "history";
import Login from "./Pages/Login";
import authProviders from "./Provider/authProvider";
import PeopleIcon from "@material-ui/icons/People";
import customRoutes from "./Utils/customRoutes";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FaceIcon from "@material-ui/icons/Face";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Layouts from "./Layouts";

//resource
import { UserCreate, UserList, UserEdit } from "./Pages/Users";
import {
  PromotionsList,
  PromoCreate,
  PromotionsEdit,
} from "./Pages/Promotions";
import { DepositList } from "./Pages/Deposits";
import { LedgerList } from "./Pages/Ledgers";
import { TransactionList } from "./Pages/Transaction";
import { VendorList, VendorShow, VendorEdit } from "./Pages/Vendor";
import { ProductList } from "./Pages/Products";
import { createMuiTheme } from "@material-ui/core/styles";

// import { ProductLayoutList } from "./Pages/ProductLayout";
const history = createHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F47820",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#F47820",
      dark: "#f7f7f7",
    },
  },
});

function App() {
  return (
    <Admin
      dashboard={Dashboard}
      dataProvider={dataProviders}
      history={history}
      customRoutes={customRoutes}
      loginPage={Login}
      authProvider={authProviders}
      layout={Layouts}
      theme={theme}
    >
      <Resource
        name="users"
        options={{ label: "Users" }}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={PeopleIcon}
      />
      <Resource
        name="promotions"
        options={{ label: "Promotions" }}
        list={PromotionsList}
        create={PromoCreate}
        icon={LocalAtmIcon}
        edit={PromotionsEdit}
      />
      <Resource
        name="deposits"
        options={{ label: "Deposits" }}
        list={DepositList}
        icon={AccountBalanceIcon}
      />
      <Resource
        name="reports"
        options={{ label: "Reports" }}
        list={LedgerList}
        icon={AssignmentIcon}
      />
      <Resource
        name="transactions"
        options={{ label: "Transactions" }}
        list={TransactionList}
        icon={ShoppingCartIcon}
        // show={TransactionShow}
      />
      <Resource
        name="vendors"
        options={{ label: "Vendors" }}
        edit={VendorEdit}
        list={VendorList}
        icon={FaceIcon}
        show={VendorShow}
      />
      <Resource
        name="products"
        options={{ label: "Products" }}
        list={ProductList}
        icon={ShoppingBasketIcon}
      />
      {/* <Resource
        name="layouts"
        options={{ label: "Product Layouts" }}
        list={ProductLayoutList}
        icon={ListAltIcon}
      /> */}
    </Admin>
  );
}

export default App;
