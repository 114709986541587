import React from "react";
import { Layout } from "react-admin";
import AppBar from "./appBar";
// import MySidebar from "./MySidebar";
import CustomizedMenu from "./customMenu";
// import MyNotification from "./MyNotification";

const MyLayout = (props: any) => (
  <Layout
    {...props}
    appBar={AppBar}
    // sidebar={MySidebar}
    menu={CustomizedMenu}
    // notification={MyNotification}
  />
);

export default MyLayout;
