import client from "../../Utils/client";
import { getUsersById } from "../../Graphql/Users";
import { getPromotionsById } from "../../Graphql/Promotion";
import { getDepositLogsById } from "../../Graphql/Deposits";
import { getTransactionsById } from "../../Graphql/Transaction";
import { getProductsById } from "../../Graphql/Products";
import errorHandler from "../../Utils/errorHandler";
const getOne = async (resource: any, params: any) => {
  console.log("getOne");
  let dataFetch;
  try {
    if (resource === "users") {
      const query = {
        query: getUsersById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: {
          ...get.getUsersById,
          roles: get.getUsersById.roles[0],
          active: get.getUsersById.deactivated_at === null,
        },
      };
    } else if (resource === "promotions") {
      const query = {
        query: getPromotionsById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: get.getPromotionsById,
      };
    } else if (resource === "vendors") {
      console.log("vendors");

      const query = {
        query: getUsersById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: {
          ...get.getUsersById,
          roles: get.getUsersById.roles[0],
          active: get.getUsersById.vendor_disabled_at === null,
        },
      };
    } else if (resource === "deposits") {
      const query = {
        query: getDepositLogsById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: get.getDepositLogsById,
      };
    } else if (resource === "products") {
      const query = {
        query: getProductsById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: get.getProductsById,
      };
    } else if (resource === "transactions") {
      const query = {
        query: getTransactionsById,
        variables: {
          id: params.id,
        },
      };
      const get = await client.gql.query(query);
      console.log(get);

      dataFetch = {
        data: get.getTransactionsById,
      };
    } else {
      dataFetch = {
        data: {},
      };
    }
    return dataFetch;
  } catch (error) {
    await errorHandler(error);
  }
};

export default getOne;
