import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EqualizerIcon from "@material-ui/icons/Equalizer";
const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div style={{ marginTop: 24 }}>
      <MenuItemLink
        to="/"
        primaryText="Performance"
        leftIcon={<EqualizerIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources
        .filter(
          (resource: any) =>
            !["content_items", "levels", "categoryNoLimit"].includes(
              resource.name
            )
        )
        .map((resource: any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItemLink
        to="/product-layout"
        primaryText="Product layouts"
        leftIcon={<ListAltIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
