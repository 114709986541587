import React from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import TextFieldMui from "@material-ui/core/TextField";
import client from "../Utils/client";
// import ReactJson from "react-json-view";
import { Delete, Save } from "@material-ui/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageIcon from "@material-ui/icons/Image";
import isProd from "../Utils/variabels";
import { useNotify } from "react-admin";
import forceLogout from "../Utils/errorHandler";

interface Props {
  item: any;
  refetch: any;
  setItem: any;
}

const EditParent = ({ item, refetch, setItem }: Props) => {
  const [icon, setIcon] = React.useState<any>(null);
  const [label, setLabel] = React.useState<string>("");
  const [newImageFile, setNewImageFile] = React.useState<any>(null);
  const [nameFile, setNameFile] = React.useState("");
  const [cropper, setCropper] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = React.useState(0);
  const notifier = useNotify();

  const fileInputRef = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    // console.log("change");
    setDataInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const setDataInit = () => {
    setIcon(item.value.icon);
    setLabel(item.value.label);
    setNewImageFile(null);
  };

  const deleteData = async () => {
    setLoading(true);
    try {
      const table = client.getTable("product_layouts");
      const deleteData = await table.del({
        id: item.id,
      });

      console.log(deleteData);
      setItem([]);

      //refresh
      await refetch();
      setLoading(false);
    } catch (e) {
      console.log(e.response.data);
      setLoading(false);
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log("submit");
    // create new key

    if (nameFile === "") {
      // add to table
      console.log("BIASA");

      try {
        const table = client.getTable("product_layouts");
        const update = await table.update({
          id: item.id,
          data: {
            value: {
              icon,
              label,
            },
          },
        });
        console.log(update);

        //refresh
        await refetch();
        setLoading(false);
      } catch (e) {
        console.log(e);
        await forceLogout(e);
      }
    } else {
      await uploadImage();
    }
  };

  const uploadImage = async () => {
    cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toBlob(async (blob: any) => {
        console.log(blob);
        try {
          const uploadResult = await client.storage.upload({
            file: blob,
            progressCallback: (progressVal: number) => setProgress(progressVal),
            params: {
              filename: `ProductLayout/${nameFile}`,
              bucket: isProd.publicBucket,
            },
          });
          console.log(uploadResult);
          const download = await client.storage.downloadUrl({
            params: {
              bucket: isProd.publicBucket,
            },
            fileId: uploadResult.data.id,
          });

          console.log(download);
          const urlImg = download.data.url.split("?");
          console.log(urlImg[0]);

          // add to table
          const table = client.getTable("product_layouts");
          const update = await table.update({
            id: item.id,
            data: {
              value: {
                icon: urlImg[0],
                label,
              },
            },
          });
          console.log(update);

          //refresh
          await refetch();

          //cleanup
          setLoading(false);
          notifier("Edit Parent Success");
          // cleanup();
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
          await forceLogout(e);
          // cleanup();
        }
      }, "image/png");
  };

  const handleFileChange = (event: any) => {
    const name = event.target.files[0].name;
    setIcon(null);
    setNameFile(name);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };

  const resetImageSelect = () => {
    setNewImageFile(null);
  };

  const submitCrop = () => {
    const test = cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toDataURL("image/png");
    setIcon(test);
    resetImageSelect();
  };

  return (
    <div style={{ margin: "" }}>
      <div
        style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "bold" }}
      >
        <Typography variant="h6">Edit Parent</Typography>

        <Divider style={{ marginTop: "10px" }} />
      </div>
      <form onSubmit={submit}>
        <Grid container direction="row" style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {icon && (
              <img
                src={icon}
                alt="current img"
                style={{ width: "100px", height: "100px" }}
              />
            )}
            <input
              type="file"
              hidden
              ref={fileInputRef}
              onChange={(e) => handleFileChange(e)}
              accept="image/*"
            />
            {!newImageFile && (
              <div style={{ margin: "10px 0px" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ImageIcon />}
                  onClick={() => fileInputRef?.current?.click()}
                  size="small"
                >
                  Select Image
                </Button>
              </div>
            )}
            {newImageFile && (
              <div>
                <Cropper
                  src={newImageFile}
                  style={{
                    width: "200px",
                    height: "200px",
                    alignSelf: "center",
                  }}
                  // Cropper.js options
                  aspectRatio={100 / 100}
                  viewMode={1}
                  guides={true}
                  scalable={true}
                  onInitialized={(cropperInstance) =>
                    setCropper(cropperInstance)
                  }
                />
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "15px",
                    textAlign: "center",
                    width: "200px",
                    overflowX: "auto",
                  }}
                >
                  {nameFile}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<ImageIcon />}
                    onClick={() => fileInputRef?.current?.click()}
                    size="small"
                  >
                    Select Image
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submitCrop()}
                    style={{ marginTop: "10px" }}
                    size="small"
                    startIcon={<Save />}
                  >
                    Save Image
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={8}>
            <TextFieldMui
              label="Label"
              value={label}
              required
              fullWidth
              // helperText="Please fill note for approve deposits"
              onChange={(e) => setLabel(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            {/* <ReactJson
              src={item}
              collapsed={0}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              collapseStringsAfterLength={50}
            /> */}
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CircularProgress
              style={{ width: "50px", height: "50px", marginRight: "40px" }}
            />
          </div>
        ) : (
          <Grid
            container
            direction="row"
            style={{ marginBottom: "20px" }}
            justify="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginRight: "10px" }}
              disabled={loading || newImageFile}
              startIcon={<Save />}
            >
              {loading ? "load .." : "Save"}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Delete />}
              onClick={deleteData}
              disabled={loading}
            >
              Delete
            </Button>
          </Grid>
        )}
      </form>
    </div>
  );
};

export default EditParent;
