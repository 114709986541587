/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, makeStyles, Divider } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import getParam from "../Utils/getParam";
import client from "../Utils/client";
import { useRedirect } from "react-admin";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const loginStyles: any = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    width: "400px",
    minHeight: "530px",
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "20px",
    textAlign: "center",
  },
  buttonGroup: {
    margin: "5px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    marginBottom: "20px",
  },
  button2: {
    marginBottom: "20px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: "#efefef",
      color: "black",
    },
  },
  text: {
    textAlign: "center",
    margin: "20px 0px",
    fontWeight: "bold",
  },
  text2: {
    textAlign: "center",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "20px 0px",
    color: "#F47820",
    cursor: "pointer",
    textDecoration: "none",
  },
  input: {
    marginBottom: "20px",
  },
  logo: {
    width: "300px",
    height: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
  },
  google: {
    width: "25px",
  },
  divider: {
    marginBottom: "20px",
  },
  error: {
    marginBottom: "10px",
  },
  success: {
    marginBottom: "10px",
  },
}));
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Login() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const redirect = useRedirect();

  const classes = loginStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirm, setConfirm] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [errorReset, setErrorReset] = React.useState(false);

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const reset = async () => {
    setErrorReset(false);
    const token = getParam("token");
    console.log(token);

    const resetPassword = await client.auth.resetPassword(token, password);
    console.log(resetPassword);

    if (resetPassword.message === "User password reset") {
      if (localStorage.getItem("MBaaS.auth.accessToken")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const logout = await client.user.logout();
        localStorage.removeItem("permission");
      }
      setPassword("");
      setConfirm("");
      setOpen(true);
      // redirect("/login");
    } else {
      setPassword("");
      setConfirm("");
      setErrorReset(true);
      console.log("error");
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div style={{ textAlign: "center" }}>
          <img src="/logo.png" alt="logo" className={classes.logo} />{" "}
        </div>
        <div className="login">
          <div className={classes.text}>Reset Password</div>

          <div className={classes.buttonGroup}>
            {errorReset && (
              <MuiAlert severity="error" className={classes.error}>
                Token invalid
              </MuiAlert>
            )}
            <FormControl className={classes.input} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={110}
              />
            </FormControl>
            <FormControl className={classes.input} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showPassword ? "text" : "password"}
                value={confirm}
                error={password !== confirm}
                onChange={(e) => setConfirm(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={170}
              />
            </FormControl>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => reset()}
              className={classes.button}
            >
              RESET PASSWORD
            </Button>
            <Divider />
            <Link to="/login" className={classes.text2}>
              Back to Login Page
            </Link>
          </div>
        </div>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success reset password, please try login
        </Alert>
      </Snackbar>
    </div>
  );
}
