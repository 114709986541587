import gql from "graphql-tag";

export const allUsersList = gql`
  query allUsersList(
    $limit: Int
    $offset: Int
    $sort: UsersSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: UsersWhereInput
  ) {
    allUsersList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        deactivated_at
        roles
        name
        email
        social_ids
        verified
        msisdn
        line_id
        line_metadata
        vendor_type
        vendor_disabled_at
        ledgers_user_id_list(
          sort: created_at
          order: DESC
          limit: 1
          where: { balance: { _is_null: false } }
        ) {
          data {
            balance
          }
        }
        registration_metadata
        encrypted_2fa
        vendor_details_user_id_list {
          data {
            id
            created_at
            updated_at
            user_id
            user_id_data {
              name
              email
            }
            address
            city
            postal_code
            vat_number
            vat_name
            vat_image
            arc_image
            arc_expiry_date
          }
        }
      }
      count
    }
  }
`;

export const getUsersById = gql`
  query getUsersById($id: UUID!) {
    getUsersById(id: $id) {
      id
      created_at
      updated_at
      deactivated_at
      roles
      name
      email
      social_ids
      verified
      msisdn
      line_id
      line_metadata
      vendor_type
      vendor_disabled_at
      registration_metadata
      encrypted_2fa
      ledgers_user_id_list(
        sort: created_at
        order: DESC
        limit: 1
        where: { balance: { _is_null: false } }
      ) {
        data {
          balance
        }
      }
      vendor_details_user_id_list {
        data {
          id
          created_at
          updated_at
          user_id
          user_id_data {
            name
            email
          }
          address
          city
          postal_code
          vat_number
          vat_name
          vat_image
          arc_image
          arc_expiry_date
        }
      }
    }
  }
`;

export const createUsers = gql`
  mutation createUsers($input: [UsersCreateInput!]!) {
    createUsers(input: $input) {
      id
      created_at
      updated_at
      deactivated_at
      roles
      email
      password
      social_ids
      verified
    }
  }
`;

export const deleteUsers = gql`
  mutation deleteUsersById($ids: [UUID!]!) {
    deleteUsersById(ids: $ids)
  }
`;
