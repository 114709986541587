const testEnv = {
  privateBucket: "private-files",
  publicBucket: "public-assets",
  apiUrl: "https://dress-test.appserver1.telin.tw",
  dashboard:
    "https://metabase.appserver1.telin.tw/public/dashboard/a303fd00-24f5-426b-9d81-1f4b244c2a9d#bordered=false&titled=false",
};

const productionEnv = {
  privateBucket: "private-files",
  publicBucket: "public-assets",
  apiUrl: "https://maimai-api.appgw.telin.tw",
  dashboard:
    "https://metabase.appserver1.telin.tw/public/dashboard/5c72fef9-8e10-4856-a330-4744e5079c3f#bordered=false&titled=false",
};

const isProduction = window.location.host === "maimai-portal.telin.tw";

const validEnv = isProduction ? productionEnv : testEnv;

export default validEnv;
