import client from "../../Utils/client";
import isProd from "../../Utils/variabels";
import { LexoRank } from "lexorank";
import dataProvider from "../dataProvider";
import errorHandler from "../../Utils/errorHandler";

const update = async (resource: any, params: any) => {
  const table = client.getTable(resource === "vendors" ? "users" : resource);
  console.log("update");
  let dataEdit;
  try {
    if (resource === "users") {
      let dataUpdate = { ...params.data, roles: params.data.roles.split(" ") };

      if (params.data.active) {
        dataUpdate = { ...dataUpdate, deactivated_at: null };
      } else {
        const date = new Date().toISOString();
        dataUpdate = { ...dataUpdate, deactivated_at: date };
      }

      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      console.log(dataFixUpdate);

      dataEdit = await table.update(dataFixUpdate);
    } else if (resource === "vendors") {
      let dataUpdate = { ...params.data, roles: params.data.roles.split(" ") };

      if (params.data.active) {
        dataUpdate = { ...dataUpdate, vendor_disabled_at: null };
      } else {
        const date = new Date().toISOString();
        dataUpdate = { ...dataUpdate, vendor_disabled_at: date };
      }

      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      console.log(dataFixUpdate);

      dataEdit = await table.update(dataFixUpdate);
    } else if (resource === "promotions") {
      let dataUpdate = params.data;

      if (params.data.position) {
        const get = await dataProvider.getOne(resource, {
          id: params.data.priorityValue,
        });
        const referenceRank = get.data.priority;
        console.log(referenceRank);

        if (params.data.position === "after") {
          const after = LexoRank.parse(referenceRank).genNext().toString();
          console.log(after);

          dataUpdate = {
            ...dataUpdate,
            priority: after,
          };
        } else if (params.data.position === "before") {
          const before = LexoRank.parse(referenceRank).genPrev().toString();
          console.log("before");

          console.log(before);

          dataUpdate = {
            ...dataUpdate,
            priority: before,
          };
        }
      }

      if (params.data.priority === null) {
        dataUpdate = {
          ...dataUpdate,
          priority: LexoRank.middle().toString(),
        };
      }

      if (params.data.upload) {
        const upload = await client.storage.upload({
          params: {
            filename: params.data.upload.title,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.upload.rawFile,
          progressCallback: (progressVal: any) => console.log(progressVal),
        });
        console.log(upload);
        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");

        delete dataUpdate.upload;
        const dataFixUpdate = {
          id: params.id,
          data: { ...dataUpdate, image: urlImg[0] },
        };
        console.log(dataFixUpdate);

        dataEdit = await table.update(dataFixUpdate);
      } else {
        console.log("insert");
        console.log(dataUpdate);

        const dataFixUpdate = {
          id: params.id,
          data: dataUpdate,
        };
        console.log(dataFixUpdate);

        dataEdit = await table.update(dataFixUpdate);
      }
    } else {
      dataEdit = {
        data: [],
        total: 0,
      };
    }
    console.log(dataEdit);

    return dataEdit;
  } catch (error) {
    await errorHandler(error);
  }
};

export default update;
