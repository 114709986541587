import client from "../../Utils/client";
import errorHandler from "../../Utils/errorHandler";

const deleteReq = async (resource: any, params: any) => {
  try {
    console.log("delete");
    const table = client.getTable(resource === "vendors" ? "users" : resource);
    const deleteData = await table.del({ id: params.id });
    console.log(deleteData);

    return { data: params.previousData };
  } catch (error) {
    await errorHandler(error);
  }
};

export default deleteReq;
