/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, Divider, Grid, Hidden, Typography } from "@material-ui/core";
import TextFieldMui from "@material-ui/core/TextField";
import client from "../Utils/client";
import { LexoRank } from "lexorank";
import { Save } from "@material-ui/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageIcon from "@material-ui/icons/Image";
import isProd from "../Utils/variabels";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  items: any;
  refetch: any;
}

const AddNewParent = ({ items, refetch }: Props) => {
  const [icon, setIcon] = React.useState<string>("");
  const [label, setLabel] = React.useState<string>("");
  const [newImageFile, setNewImageFile] = React.useState<any>(null);
  const [nameFile, setNameFile] = React.useState("");
  const [cropper, setCropper] = React.useState<any>(null);
  const [imageBlob, setImageBlob] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState(0);
  const notifier = useNotify();

  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleFileChange = (event: any) => {
    const name = event.target.files[0].name;
    setImageBlob(null);
    setNameFile(name);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };
  const uploadImage = async () => {
    cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toBlob(async (blob: any) => {
        console.log(blob);
        try {
          const uploadResult = await client.storage.upload({
            file: blob,
            progressCallback: (progressVal: number) => setProgress(progressVal),
            params: {
              filename: `ProductLayout/${nameFile}`,
              bucket: isProd.publicBucket,
            },
          });
          console.log(uploadResult);
          const download = await client.storage.downloadUrl({
            params: {
              bucket: isProd.publicBucket,
            },
            fileId: uploadResult.data.id,
          });

          console.log(download);
          const urlImg = download.data.url.split("?");
          console.log(urlImg[0]);

          let newKey;
          if (items.length === 0) {
            newKey = LexoRank.middle().toString();
          } else {
            newKey = LexoRank.parse(items[items.length - 1].key)
              .genNext()
              .toString();
          }
          console.log(newKey);
          // add to table
          const table = client.getTable("product_layouts");
          const insert = await table.insert({
            value: {
              icon: urlImg[0],
              label,
            },
            key: newKey,
          });
          console.log(insert);

          //refresh
          await refetch();

          //cleanup
          setImageBlob(null);
          setLabel("");
          localStorage.removeItem("imageUrl");
          setIcon("");
          setLoading(false);
          notifier("Add New Parent Success");
          // cleanup();
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
          // await forceLogout(e);
          // cleanup();
        }
      }, "image/png");
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log("submit");
    await uploadImage();
  };

  const resetImageSelect = () => {
    setNewImageFile(null);
  };

  const submitCrop = () => {
    const test = cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toDataURL("image/png");
    setImageBlob(test);
    resetImageSelect();
  };

  return (
    <div>
      <div
        style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "bold" }}
      >
        <Typography variant="h6">Add New Parent</Typography>

        <Divider style={{ marginTop: "10px" }} />
      </div>
      <form onSubmit={submit}>
        <Grid container direction="row" style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {imageBlob ? (
              <img
                src={imageBlob}
                alt="current img"
                style={{ width: "100px", height: "100px" }}
              />
            ) : (
              !newImageFile && (
                <img
                  src={
                    "https://placehold.jp/30/F47820/FFFFFF/100x100.jpg?text=Select Image"
                  }
                  alt="current img"
                  style={{ width: "100px", height: "100px" }}
                />
              )
            )}
            <input
              type="file"
              hidden
              ref={fileInputRef}
              required
              onChange={(e) => handleFileChange(e)}
              accept="image/*"
            />
            {!newImageFile && (
              <div style={{ margin: "10px 0px" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ImageIcon />}
                  onClick={() => fileInputRef?.current?.click()}
                  size="small"
                >
                  Select Image
                </Button>
              </div>
            )}
            {newImageFile && (
              <div>
                <Cropper
                  src={newImageFile}
                  style={{
                    width: "200px",
                    height: "200px",
                    alignSelf: "center",
                  }}
                  // Cropper.js options
                  aspectRatio={100 / 100}
                  viewMode={1}
                  guides={true}
                  scalable={true}
                  onInitialized={(cropperInstance) =>
                    setCropper(cropperInstance)
                  }
                />
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "15px",
                    textAlign: "center",
                    width: "200px",
                    overflowX: "auto",
                  }}
                >
                  {nameFile}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<ImageIcon />}
                    onClick={() => fileInputRef?.current?.click()}
                    size="small"
                  >
                    Select Image
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submitCrop()}
                    style={{ marginTop: "10px" }}
                    size="small"
                    startIcon={<Save />}
                  >
                    Save Image
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={8}>
            <TextFieldMui
              label="Label"
              value={label}
              required
              fullWidth
              helperText="Label name for parents"
              onChange={(e) => setLabel(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ marginBottom: "20px" }}
          justify="flex-end"
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="small"
            startIcon={loading ? "" : <Save />}
            disabled={loading || imageBlob === null}
          >
            {loading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "Save New Parent"
            )}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default AddNewParent;
