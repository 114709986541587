import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Zoom from "react-img-zoom";

interface review {
  openImageReview: any;
  img: string;
  setImageReview: any;
}

const ImageReviewer = (props: review) => {
  return (
    <div>
      <Dialog
        open={props.openImageReview}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Image Viewer</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <img src={img} alt="imgpreview" /> */}
            <Zoom img={props.img} zoomScale={2} width={900} height={500} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => props.setImageReview(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageReviewer;
