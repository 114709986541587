import gql from "graphql-tag";

export const allDepositLogsList = gql`
  query allDepositLogsList(
    $limit: Int
    $offset: Int
    $sort: DepositLogsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: DepositLogsWhereInput
  ) {
    allDepositLogsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        attachment
        channel
        amount
        charge
        channel_id
        channel_metadata
        status
        histories
        created_by
        created_by_data {
          id
          name
          email
        }
        reference_id
        approved_by
        approved_by_data {
          id
          name
          email
        }
      }
      count
    }
  }
`;

export const getDepositLogsById = gql`
  query getDepositLogsById($id: UUID!) {
    getDepositLogsById(id: $id) {
      id
      created_at
      updated_at
      channel
      amount
      attachment
      charge
      channel_id
      channel_metadata
      status
      histories
      created_by
      created_by_data {
        name
        email
      }
      reference_id
      approved_by
      approved_by_data {
        id
        name
        email
      }
    }
  }
`;
