import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
} from "@material-ui/core";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  // ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  Show,
  SimpleShowLayout,
  ShowController,
} from "react-admin";
import TextFieldMui from "@material-ui/core/TextField";
import Alerta from "@material-ui/lab/Alert";
import swal from "sweetalert";
import isProd from "../Utils/variabels";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert, Autocomplete } from "@material-ui/lab";
import dataProvider from "../Provider/dataProvider";
import client from "../Utils/client";
import ImageIcon from "@material-ui/icons/Image";

import { Refresh } from "@material-ui/icons";
import moment from "moment";
import exportFile from "../Utils/ExportFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import ImageReviewer from "../Components/ImageReview";

export const DepositList = (props: any) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogApprove, setOpenDialogApprove] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [wrongOtp, setWrongOtp] = React.useState(false);
  const [userData, setUserData] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<any>("");
  const [selectedApp, setSelectedApp] = React.useState<any>("");
  const [amount, setAmount] = React.useState("");
  const [note, setNote] = React.useState("");
  const [noteApp, setNoteapp] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [successExport, setSuccessExport] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingApprove, setLoadingApprove] = React.useState(false);
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [openImageReview, setImageReview] = React.useState<boolean>(false);
  const [img, setImg] = React.useState("");
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setWrongOtp(false);
    setOtp("");
    setSelected("");
  };

  const handleCloseDialogApprove = () => {
    setOpenDialogApprove(false);
    setNoteapp("");
    setOtp("");
  };

  const lookFile = async (filename: string) => {
    const download = await client.storage.downloadUrl({
      params: {
        bucket: isProd.privateBucket,
      },
      fileId: filename,
    });
    console.log(download);
    // window.open(download.data.url, "_blank");
    setImg(download.data.url);
    // setImg(
    //   "https://www.ups.com/assets/resources/images/knowledge-center/934x495/m10-934x495-hero-main-A-guide-to-value-added-tax.jpg"
    // );

    setImageReview(true);
  };

  const ListActions = (props: any) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      // currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      // basePath,
      // selectedIds,
      showFilter,
      // total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {/* {isAdmin && <CreateButton basePath={basePath} />} */}
        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<MonetizationOnIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Inject
        </Button>

        {/* <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        /> */}

        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={() => exportFile(filterValues, "DEPOSIT", setSuccessExport)}
        >
          Export
        </Button>
      </TopToolbar>
    );
  };

  React.useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    setWrongOtp(false);

    const user = await dataProvider.getList("users", {
      filter: { roles: "Vendor" },
      pagination: { page: 1, perPage: 111000 },
      sort: { field: "name", order: "ASC" },
    });
    console.log(user.data);
    setUserData(user.data);
    setLoading(false);
  };

  const injectDepo = async () => {
    setLoading(true);
    const confirmOtp = await client.invokeApi("portal-deposit-inject", {
      vendorId: selected.id,
      amount: parseInt(amount),
      remark: note,
      totpCode: otp,
    });
    console.log(confirmOtp);
    setLoading(false);

    if (confirmOtp.data) {
      setOtp("");
      setAmount("");
      setNote("");
      setSuccess(true);
      setOpenDialog(false);
    } else {
      setOtp("");
      setWrongOtp(true);
    }
  };

  const recheckStatus = async (record: any) => {
    setLoadingStatus(true);
    console.log(record);

    const resync = await client.invokeApi("portal-resync-paygateway", {
      paymentId: record.channel_id,
    });
    console.log(resync);

    setLoadingStatus(false);
    if (resync.data.status === "ERNOTPAID") {
      swal(record.channel_id, "This deposits not paid", "error");
    } else {
      swal(record.channel_id, "This deposits already paid", "success");
    }
  };

  const approveManual = async () => {
    setLoadingApprove(true);
    setWrongOtp(false);
    const approve = await client.invokeApi("portal-deposit-approval", {
      depositId: selectedApp,
      status: "APPROVE",
      note: noteApp,
      totpCode: otp,
      amount: parseInt(amount),
    });
    console.log(approve);

    if (approve.data) {
      setOtp("");
      setNoteapp("");
      setOpenDialogApprove(false);
      setLoadingApprove(false);
    } else {
      setOtp("");
      setLoadingApprove(false);
      setWrongOtp(true);
    }
  };

  const ChannelInfo = ({ record }: any) => {
    return (
      <div>
        <div>
          <b>{record.channel_id === null ? "-" : record.channel_id}</b>
        </div>
        <div>
          <b>{record.channel === null ? "-" : record.channel}</b>
        </div>
        <div>
          <b>
            {record.channel_metadata.barcode2nd === null
              ? "-"
              : record.channel_metadata.barcode2nd}
          </b>
        </div>
      </div>
    );
  };
  ChannelInfo.defaultProps = { label: "Channel Info", addLabel: true };

  const ButtonMenu = ({ record }: any) => {
    return (
      <React.Fragment>
        {record.channel !== "MANUAL" && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={loadingStatus ? "" : <Refresh />}
                  onClick={() => recheckStatus(record)}
                >
                  {loadingStatus ? (
                    <CircularProgress
                      style={{
                        width: "21px",
                        height: "21px",
                        margin: "0px 20px",
                      }}
                    />
                  ) : (
                    "Status"
                  )}
                </Button>
              </div>
            </div>
            <Divider />
          </div>
        )}
      </React.Fragment>
    );
  };

  const ButtonAttac = ({ record }: any) => {
    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        startIcon={<ImageIcon />}
        style={{ marginTop: "10px" }}
        onClick={() => lookFile(record.attachment)}
      >
        Attachment
      </Button>
    );
  };

  const ExpandShow = (props: any) => (
    <div>
      <ShowController {...props}>
        {(controllerProps: any) => (
          <Show {...props} title=" ">
            <SimpleShowLayout>
              <ButtonMenu />
              {controllerProps.record &&
                controllerProps.record.channel === "MANUAL" &&
                controllerProps.record.status === "PENDING" && (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "5px", backgroundColor: "#53d653" }}
                      startIcon={
                        loadingApprove ? "" : <CheckCircleOutlineIcon />
                      }
                      onClick={() => {
                        setSelectedApp(props.record.id);
                        setOpenDialogApprove(true);
                      }}
                    >
                      Approve this deposit
                    </Button>
                  </div>
                )}
              <FunctionField
                label="Channel Id"
                render={(record: any) =>
                  record.channel_id === null ? "-" : record.channel_id
                }
              />
              <TextField source="channel" />
              <TextField source="charge" />
              <TextField source="amount" />
              <FunctionField
                label="Approved by"
                render={(record: any) =>
                  record.approved_by === null
                    ? "-"
                    : record.approved_by_data.name
                }
              />

              <FunctionField
                label="Status"
                render={(record: any) => (
                  <Chip
                    label={record.status}
                    style={{
                      backgroundColor:
                        record.status === "UNPAID"
                          ? "#f9d9d9"
                          : record.status === "PAID"
                          ? "#bfffbf"
                          : "#fff696",
                    }}
                  />
                )}
              />
              {controllerProps.record && controllerProps.record.attachment && (
                <ButtonAttac />
              )}
            </SimpleShowLayout>
          </Show>
        )}
      </ShowController>
    </div>
  );

  const DepoStyle = (record: any, index: number) => ({
    backgroundColor:
      record.status === "UNPAID"
        ? ""
        : record.status === "PAID"
        ? "#dcedc8"
        : "#ffcdd2",
  });

  const fromData = moment().subtract(30, "days").format();
  const toData = moment().format();

  return (
    <React.Fragment>
      <List
        {...props}
        filters={<DepoFilter />}
        bulkActionButtons={false}
        actions={<ListActions />}
        sort={{ field: "created_at", order: "DESC" }}
        // filter={{ vendor_id: "3fb78bb8-153a-4bf0-800c-9945dd25abd3" }}
        filterDefaultValues={{
          from: fromData,
          to: toData,
        }}
      >
        <Datagrid expand={<ExpandShow />} rowStyle={DepoStyle}>
          <ChannelInfo />

          <TextField source="charge" />
          <TextField source="amount" />
          <TextField source="created_by_data.name" label="Created by" />

          <FunctionField
            label="Approved by"
            render={(record: any) =>
              record.approved_by === null ? "-" : record.approved_by_data.name
            }
          />
          <TextField source="status" />
          <DateField locales="en-TT" source="created_at" showTime />
          <DateField locales="en-TT" source="updated_at" showTime />
        </Datagrid>
      </List>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success deposit inject
        </Alert>
      </Snackbar>
      <Snackbar
        open={successExport}
        autoHideDuration={3000}
        onClose={() => setSuccessExport(false)}
      >
        <Alert onClose={() => setSuccessExport(false)} severity="success">
          Success export Deposits, check Reports
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Deposit Inject</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div>
            <Autocomplete
              options={userData}
              fullWidth
              style={{ marginBottom: "15px" }}
              getOptionLabel={(option: any) => option.name}
              onChange={(event, newValue) => {
                console.log(newValue);

                setSelected(newValue);
              }}
              renderInput={(params) => (
                <TextFieldMui
                  {...params}
                  label="Recipient"
                  variant="standard"
                />
              )}
            />
            <TextFieldMui
              label="Amount"
              value={amount}
              fullWidth
              type="number"
              onChange={(e) => setAmount(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <TextFieldMui
              label="Note"
              value={note}
              fullWidth
              onChange={(e) => setNote(e.target.value)}
              style={{ marginBottom: "10px" }}
            />

            {wrongOtp && <Alerta severity="error">Token Invalid!</Alerta>}
            <TextFieldMui
              label="OTP"
              value={otp}
              fullWidth
              onChange={(e) => setOtp(e.target.value)}
              style={{ marginBottom: "20px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={injectDepo}
            color="primary"
            variant="contained"
            disabled={
              otp === "" ||
              note === "" ||
              selected === "" ||
              amount === "" ||
              loading
            }
          >
            {loading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "Inject"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* approve manual */}
      <Dialog
        open={openDialogApprove}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Approve deposits</DialogTitle>
        <DialogContent>
          <div>
            <TextFieldMui
              label="Note"
              value={noteApp}
              fullWidth
              required
              helperText="Please fill note for approve deposits"
              onChange={(e) => setNoteapp(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <TextFieldMui
              label="Amount"
              value={amount}
              fullWidth
              required
              onChange={(e) => setAmount(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            {wrongOtp && <Alerta severity="error">Token Invalid!</Alerta>}
            <TextFieldMui
              label="OTP"
              value={otp}
              fullWidth
              onChange={(e) => setOtp(e.target.value)}
              style={{ marginBottom: "10px", marginTop: "5px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogApprove}
            color="primary"
            disabled={loadingApprove}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={noteApp === "" || loadingApprove || otp === ""}
            onClick={approveManual}
          >
            {loadingApprove ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "Approve"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <ImageReviewer
        openImageReview={openImageReview}
        img={img}
        setImageReview={setImageReview}
      />
    </React.Fragment>
  );
};

const DepoFilter = (props: any) => (
  <Filter {...props} style={{ paddingTop: "10px", paddingBottom: "5px" }}>
    <DateInput source="from" label="Begin" alwaysOn />
    <DateInput source="to" label="End" alwaysOn />
    <ReferenceInput
      label="Vendor"
      source="vendor_id"
      alwaysOn
      perPage={100000}
      reference="users"
      filter={{ roles: "Vendor" }}
    >
      <AutocompleteInput
        optionText="name"
        style={{ background: "white", marginTop: "10px" }}
      />
    </ReferenceInput>
    <SelectInput
      alwaysOn
      source="status"
      choices={[
        { id: "UNPAID", name: "UNPAID" },
        { id: "PAID", name: "PAID" },
        { id: "PENDING", name: "PENDING" },
      ]}
    />
    <ReferenceInput
      alwaysOn
      label="Channel"
      source="channel"
      reference="deposits"
      perPage={100000}
      filter={{ type: "reference" }}
    >
      <AutocompleteInput optionText="channel" style={{ background: "white" }} />
    </ReferenceInput>
  </Filter>
);
