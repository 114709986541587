import gql from "graphql-tag";

export const allTransactionsList = gql`
  query allTransactionsList(
    $limit: Int
    $offset: Int
    $sort: TransactionsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: TransactionsWhereInput
  ) {
    allTransactionsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        vendor_id
        vendor_id_data {
          id
          name
          email
        }
        order_id
        price
        product_snapshot
        target
        target_details
        status
        status_details
        fascon_sync_timestamp
      }
      count
    }
  }
`;

export const getTransactionsById = gql`
  query getTransactionsById($id: UUID!) {
    getTransactionsById(id: $id) {
      id
      created_at
      updated_at
      vendor_id
      vendor_id_data {
        id
        name
        email
      }
      order_id
      price
      product_snapshot
      target
      target_details
      status
      status_details
      fascon_sync_timestamp
    }
  }
`;
