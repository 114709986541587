/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import exportFile from "../Utils/ExportFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import client from "../Utils/client";
import { allDepositLogsList } from "../Graphql/Deposits";
import moment from "moment";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const headCells = [
  {
    id: "channel_id",
    numeric: false,
    disablePadding: false,
    label: "Channel ID",
  },
  { id: "channel", numeric: false, disablePadding: false, label: "Channel" },
  { id: "charge", numeric: false, disablePadding: false, label: "Charge" },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "approved_by",
    numeric: false,
    disablePadding: false,
    label: "Approved by",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Updated at",
  },
];

function EnhancedTableHead(props: any) {
  const {
    // classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    // onRequestSort,
  } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props: any) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    margin: "20px 0px",
    padding: "10px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  filter: {
    marginRight: "20px",
  },
  filterBox: {
    margin: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setloading] = React.useState(true);
  const [datafetch, setdata] = React.useState<any>({});
  const fromData = moment().subtract(30, "days").format("YYYY-MM-DD");
  const toData = moment().format("YYYY-MM-DD");
  const [begin, setBegin] = React.useState(fromData);
  const [end, setEnd] = React.useState(toData);
  const [successExport, setSuccessExport] = React.useState(false);
  const idVendor = window.location.pathname.split("/")[2];

  React.useEffect(() => {
    // console.log("awal");
    // setCourse(window.location.pathname.split("/")[2]);
    fetchData();
  }, []);

  React.useEffect(() => {
    // console.log("change");
    fetchData();
  }, [rowsPerPage, page, begin, end]);

  const fetchData = async () => {
    // console.log(idVendor);

    setloading(true);
    try {
      let query;
      query = {
        query: allDepositLogsList,
        variables: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort: "id",
          order: "ASC",
          where: {
            created_by: { _eq: idVendor },
            _and: [
              { created_at: { _gte: moment(begin).format() } },
              { created_at: { _lte: moment(end).format() } },
            ],
          },
        },
      };
      const gql = await client.gql.query(query);
      console.log(gql);
      setdata(gql.allDepositLogsList);
      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = async (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSelectAllClick = async () => {};

  const handleChangeRowsPerPage = (event: any) => {
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  // const isSelected = (name: never) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Snackbar
        open={successExport}
        autoHideDuration={3000}
        onClose={() => setSuccessExport(false)}
      >
        <Alert onClose={() => setSuccessExport(false)} severity="success">
          Success export Deposits, check Reports
        </Alert>
      </Snackbar>
      <div className={classes.filterBox}>
        <div>
          <TextField
            id="date"
            label="Begin date"
            type="date"
            className={classes.filter}
            value={begin}
            onChange={(e) => setBegin(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            className={classes.filter}
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={() =>
              exportFile(
                { from: begin, to: end, vendorId: idVendor },
                "DEPOSIT",
                setSuccessExport
              )
            }
          >
            Export
          </Button>
        </div>
      </div>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <CircularProgress style={{ width: "75px", height: "75px" }} />
        </div>
      ) : (
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={datafetch.count}
              />
              <TableBody>
                {/* {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                {datafetch.data.map((row: any, index: Number) => {
                  const isItemSelected = false;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell> */}
                      <TableCell id={labelId}>
                        {row.channel_id === null ? "-" : row.channel_id}
                      </TableCell>
                      <TableCell id={labelId}>{row.channel}</TableCell>
                      <TableCell id={labelId}>{row.charge}</TableCell>
                      <TableCell id={labelId}>
                        {row.created_by_data.name}
                      </TableCell>
                      <TableCell id={labelId}>
                        {row.approved_by === null ? "-" : row.approved_by}
                      </TableCell>
                      <TableCell id={labelId}>{row.status}</TableCell>

                      <TableCell id={labelId}>
                        {moment(row.created_at).format("DD-MM-YYYY, h:mm:ss a")}
                      </TableCell>
                      <TableCell id={labelId}>
                        {moment(row.updated_at).format("DD-MM-YYYY, h:mm:ss a")}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={datafetch.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
