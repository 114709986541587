// in src/Dashboard.js
import React from "react";
import Card from "@material-ui/core/Card";
import errorHandler from "../Utils/errorHandler";
import client from "../Utils/client";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import isProd from "../Utils/variabels";
import IframeResizer from "iframe-resizer-react";

const Dashboard = () => {
  const [warn, setWarn] = React.useState(false);

  const fetchProfile = async () => {
    try {
      if (localStorage.getItem("MBaaS.auth.accessToken")) {
        const profile = await client.user.get();
        if (profile.error) {
          await errorHandler(profile);
        } else if (profile.data.encrypted_2fa === null) {
          setWarn(true);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div>
      <Card
        style={{
          padding: "20px",
        }}
      >
        {/* <iframe
          title="dashboard"
          src="https://metabase.appserver1.telin.tw/public/dashboard/a303fd00-24f5-426b-9d81-1f4b244c2a9d#bordered=false&titled=false"
          frameBorder={0}
          height={1100}
          allowTransparency
        /> */}

        <IframeResizer
          heightCalculationMethod="bodyScroll"
          frameBorder={0}
          width="100%"
          src={isProd.dashboard}
        />
      </Card>
      <Snackbar
        open={warn}
        onClose={() => setWarn(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setWarn(false)} severity="warning">
          Please add your 2fa token for your account with doing reset OTP at the
          top right side menu button
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Dashboard;
