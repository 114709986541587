import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { allProductLayoutsList } from "../Graphql/ProductLayouts";
import client from "../Utils/client";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import {
  CircularProgress,
  Box,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import Label from "@material-ui/icons/Label";
import { LexoRank } from "lexorank";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddNewParent from "./AddNewParent";
import AddNewChild from "./AddNewChild";
import EditParent from "./EditParent";
import errorHandler from "../Utils/errorHandler";
import EditChild from "./EditChild";
// fake data generator
// const getItems = (count: any) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `item ${k}`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  console.log(startIndex);
  console.log(endIndex);
  console.log(list);

  let newKey;
  if (startIndex < endIndex) {
    newKey = LexoRank.parse(list[endIndex].key).genNext().toString();
    console.log("AFTER NEXT");
  } else {
    newKey = LexoRank.parse(list[endIndex].key).genPrev().toString();
    console.log("BEFORE");
  }
  console.log(newKey);

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result[endIndex].key = newKey;
  return { result, newKey };
};

const grid = 8;

// const CustomCheckbox = withStyles({
//   root: {
//     color: "#00ff21",
//     "&$checked": {
//       color: "#00ff21",
//     },
//     marginRight: "-15px",
//     marginTop: "-5px",
//   },
//   checked: {},
// })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

// const getItemStyle = (
//   isDragging: any,
//   draggableStyle: any,
//   itemCheck: any
// ) => ({
//   // some basic styles to make the items look a bit nicer
//   userSelect: "none",
//   padding: "0px 10px 10px 10px",
//   margin: `0 ${grid}px 0 0`,
//   fontSize: "12px",
//   fontWeight: "bold",
//   textAlign: "center",
//   // minWidth: "100px",
//   // minHeight: "100px",
//   // change background colour if dragging
//   background: isDragging
//     ? "#3498DB"
//     : itemCheck === "Token PLN"
//     ? "#E74C3C"
//     : "#34495E",
//   color: "white",
//   // styles we need to apply on draggables
//   ...draggableStyle,
// });

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  overflow: "hidden",
  minHeight: "500px",
});

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: any;
  labelInfo?: string;
  labelText: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #F47820)`,
        color: "var(--tree-view-color, white)",
        fontWeight: "bold",
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent",
      },
    },
    content: {
      width: "90%",
      height: "35px",
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelIconImg: {
      marginRight: theme.spacing(1),
      width: "20px",
      height: "20px",
      borderRadius: "20px",
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {typeof LabelIcon === "string" && LabelIcon !== "" ? (
            <img src={LabelIcon} alt="icon" className={classes.labelIconImg} />
          ) : labelInfo === "Add" ? (
            <AddIcon color="inherit" className={classes.labelIcon} />
          ) : (
            <Label color="inherit" className={classes.labelIcon} />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          {/* <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography> */}
        </div>
      }
      // style={{
      //   '--tree-view-color': color,
      //   '--tree-view-bg-color': bgColor,
      // }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}
const Productlayout = () => {
  const [items, setItems] = React.useState<any>([]);
  const [loading, setloading] = React.useState(true);
  const [move, setMove] = React.useState(false);
  const [selectData, setSelectData] = React.useState<any>([]);

  const [expand, setExpand] = React.useState<Array<string>>([]);
  const [addParent, setAddParent] = React.useState(false);
  const [addChild, setAddChild] = React.useState("");

  React.useEffect(() => {
    // console.log("change");
    fetchData();
  }, []);

  const fetchData = async () => {
    // console.log(idVendor);

    setloading(true);
    try {
      let query;
      query = {
        query: allProductLayoutsList,
        variables: {
          limit: 99,
          filter: "parent_id eq null",
          sort: "key",
          order: "ASC",
        },
      };
      const gql = await client.gql.query(query);
      console.log(gql);
      setItems(gql.allProductLayoutsList.data);
      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
      await errorHandler(err);
    }
  };

  const updateRow = async (id: string, data: any) => {
    setMove(true);
    try {
      const table = client.getTable("product_layouts");

      const update = await table.update({
        id,
        data,
      });
      console.log(update);
      setMove(false);
    } catch (e) {
      await errorHandler(e);
      setMove(false);
    }
    setMove(false);
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    console.log(result);

    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "parents") {
      const itemorder = reorder(items, sourceIndex, destIndex);

      setItems(itemorder.result);
      await updateRow(result.draggableId, { key: itemorder.newKey });
    } else {
      const itemSubItemMap = items.reduce((acc: any, item: any) => {
        acc[item.id] = item.children;
        return acc;
      }, {});

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...items];
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems.data,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.children.data = reorderedSubItems.result;
          }
          return item;
        });
        setItems(newItems);

        await updateRow(result.draggableId, { key: reorderedSubItems.newKey });
      } else {
        let newSourceSubItems = sourceSubItems.data;
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = destSubItems.data;
        newDestSubItems.splice(destIndex, 0, draggedItem);

        let newKey: any;
        if (sourceIndex < destIndex) {
          newKey = LexoRank.parse(destSubItems.data[destIndex].key)
            .genNext()
            .toString();
          console.log("AFTER NEXT");
        } else {
          newKey = LexoRank.parse(destSubItems.data[destIndex].key)
            .genPrev()
            .toString();
          console.log("BEFORE");
        }
        newDestSubItems = newDestSubItems.map(
          (item: { id: string; key: string; parent_id: any }) => {
            if (item.id === result.draggableId) {
              item.key = newKey;
              item.parent_id = destParentId;
            }
            return item;
          }
        );
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.subItems = newSourceSubItems;
          } else if (item.id === destParentId) {
            item.subItems = newDestSubItems;
          }
          return item;
        });
        setItems(newItems);
        await updateRow(result.draggableId, {
          key: newKey,
          parent_id: destParentId,
        });
      }
    }
  };

  const ChildTree = ({ item }: any) => {
    console.log(item);
    return (
      <div>
        <Droppable droppableId={item.id} direction="vertical" type="child">
          {(
            provided: {
              innerRef:
                | string
                | ((instance: HTMLDivElement | null) => void)
                | React.RefObject<HTMLDivElement>
                | null
                | undefined;
              droppableProps: JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLDivElement> &
                React.HTMLAttributes<HTMLDivElement>;
              placeholder: React.ReactNode;
            },
            snapshot: { isDraggingOver: any }
          ) => (
            <div
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {item.children.data.map(
                (
                  child:
                    | {
                        id: string | number | null | undefined;
                        content: React.ReactNode;
                        value: any;
                      }
                    | any,
                  index: any
                ) => (
                  <Draggable
                    key={child.id}
                    draggableId={child.id}
                    index={index}
                  >
                    {(
                      provided: {
                        innerRef:
                          | string
                          | ((instance: HTMLDivElement | null) => void)
                          | React.RefObject<HTMLDivElement>
                          | null
                          | undefined;
                        draggableProps: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLDivElement> &
                          React.HTMLAttributes<HTMLDivElement>;
                        dragHandleProps: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLDivElement> &
                          React.HTMLAttributes<HTMLDivElement>;
                      },
                      snapshot: { isDragging: any }
                    ) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // style={getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style,
                        //   item.value.label
                        // )}
                      >
                        <StyledTreeItem
                          nodeId={child.id}
                          onClick={() => {
                            setSelectData(child);
                            setAddParent(false);
                            setAddChild("");
                          }}
                          labelText={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignContent: "center",
                              }}
                            >
                              <span>{child.value.label} </span>
                              {!move && (
                                <span {...provided.dragHandleProps}>
                                  <DragIndicatorIcon
                                    style={{
                                      fontSize: "15px",
                                      cursor: "grabbing",
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          }
                          labelIcon={child.value.icon}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {/* {!snapshot.isDraggingOver && ( */}
              <StyledTreeItem
                nodeId={`Add New ${item.id}`}
                labelText="Add New Children"
                labelInfo="Add"
                labelIcon={AddIcon}
                style={{ marginTop: snapshot.isDraggingOver ? "50px" : "0px" }}
                onClick={() => {
                  setAddParent(false);
                  setSelectData([]);
                  setAddChild(item.id);
                }}
              />
              {/* )} */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  const handleChange = (event: any, nodes: any) => {
    setExpand(nodes);
  };

  return (
    <Box p={1} mt={1} mb={1}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Grid container direction="row">
          <Grid
            item
            style={{
              width: "300px",
              maxHeight: "620px",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                margin: "20px 15px",
              }}
            >
              Product Layouts
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px",
                }}
              >
                <CircularProgress style={{ width: "75px", height: "75px" }} />
              </div>
            ) : (
              <DragDropContext onDragEnd={onDragEnd} style={{ width: "100%" }}>
                <Droppable
                  droppableId="droppable"
                  direction="vertical"
                  type="parents"
                >
                  {(
                    provided: {
                      innerRef:
                        | string
                        | ((instance: HTMLDivElement | null) => void)
                        | React.RefObject<HTMLDivElement>
                        | null
                        | undefined;
                      droppableProps: JSX.IntrinsicAttributes &
                        React.ClassAttributes<HTMLDivElement> &
                        React.HTMLAttributes<HTMLDivElement>;
                      placeholder: React.ReactNode;
                    },
                    snapshot: { isDraggingOver: any }
                  ) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <TreeView
                        defaultExpanded={expand}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        onNodeToggle={handleChange}
                      >
                        {items.map(
                          (
                            item:
                              | {
                                  id: string | number | null | undefined;
                                  content: React.ReactNode;
                                  value: any;
                                  children: any;
                                }
                              | any,
                            index: any
                          ) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              isDragDisabled={expand.includes(item.id)}
                            >
                              {(
                                provided: {
                                  innerRef:
                                    | string
                                    | ((
                                        instance: HTMLDivElement | null
                                      ) => void)
                                    | React.RefObject<HTMLDivElement>
                                    | null
                                    | undefined;
                                  draggableProps: JSX.IntrinsicAttributes &
                                    React.ClassAttributes<HTMLDivElement> &
                                    React.HTMLAttributes<HTMLDivElement>;
                                  dragHandleProps: JSX.IntrinsicAttributes &
                                    React.ClassAttributes<HTMLDivElement> &
                                    React.HTMLAttributes<HTMLDivElement>;
                                },
                                snapshot: { isDragging: any }
                              ) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  // style={{
                                  //   background: "yellow",
                                  //   width: "300px",
                                  // }}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style,
                                  //   item.value.label
                                  // )}
                                >
                                  <StyledTreeItem
                                    nodeId={item.id}
                                    labelText={
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                        }}
                                      >
                                        <span>{item.value.label} </span>
                                        {!expand.includes(item.id) && !move && (
                                          <span {...provided.dragHandleProps}>
                                            <DragIndicatorIcon
                                              style={{
                                                fontSize: "15px",
                                                cursor: "grabbing",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    }
                                    labelIcon={item.value.icon}
                                    style={{
                                      minWidth: "280px",
                                    }}
                                    onClick={() => {
                                      setSelectData(item);
                                      setAddParent(false);
                                      setAddChild("");
                                    }}
                                  >
                                    <ChildTree item={item} />
                                  </StyledTreeItem>
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                        <StyledTreeItem
                          nodeId="Add New Parent"
                          labelText="Add New Parent"
                          labelIcon={AddIcon}
                          labelInfo="Add"
                          onClick={() => {
                            setAddParent(true);
                            setAddChild("");
                            setSelectData([]);
                          }}
                          style={{
                            marginTop: snapshot.isDraggingOver ? "50px" : "0px",
                            minWidth: "280px",
                          }}
                        />
                      </TreeView>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>
          <Grid item xs>
            <Paper
              elevation={1}
              style={{ padding: "20px", minHeight: "400px", margin: "10px" }}
            >
              {addParent ? (
                <AddNewParent items={items} refetch={fetchData} />
              ) : addChild !== "" ? (
                <AddNewChild
                  items={items}
                  refetch={fetchData}
                  parent_id={addChild}
                />
              ) : selectData.length !== 0 ? (
                !selectData.parent_id ? (
                  <EditParent
                    item={selectData}
                    refetch={fetchData}
                    setItem={setSelectData}
                  />
                ) : (
                  <EditChild
                    item={selectData.value}
                    items={selectData}
                    refetch={fetchData}
                    setItem={(data: any) =>
                      setSelectData({ ...selectData, value: data })
                    }
                    setItems={setSelectData}
                  />
                )
              ) : (
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  direction="column"
                  style={{
                    height: "350px",
                    textAlign: "center",
                  }}
                  spacing={1}
                >
                  <Grid item>
                    {/* <TouchAppIcon
                      style={{ fontSize: "100px", color: "#2875CA" }}
                    /> */}
                    <img
                      src="/logo2.gif"
                      alt="logox"
                      style={{ width: "250px" }}
                    />
                  </Grid>
                  <Grid item style={{ fontWeight: "bold" }}>
                    Select Tree Item
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Productlayout;
