import client from "./client";
async function exportFile(props: any, name: string, setAlert: any) {
  console.log(props);
  const exportFile = await client.invokeApi("portal-generate-report", {
    type: name,
    metadata: {
      begin: props.from,
      end: props.to,
      vendorId: props.vendor_id || "",
    },
  });
  console.log(exportFile);
  setAlert(true);
}
export default exportFile;
